
import { Line } from '@ant-design/charts';

const SensorMultilineGraph = props => {

  

  var config = {
  
    data: props.data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',

    yAxis: {
      label: {
        formatter: function formatter(v) {
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
    },
  };
  return (
    <Line
      {...config}
      style={{
        width: '100%',
        height: '300px',
        border: 'none',
        boxShadow: '0 3px 6px 0 #0000001a',
        borderRadius: '6px',
        padding: '20px',
        background: '#fff',
        marginTop: '15px',
      }}
    />
  );
};

export default SensorMultilineGraph;
