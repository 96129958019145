import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import '../../temp_iot/src/components/css/index.css';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.variable.min.css';
//Redux

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

ConfigProvider.config({
  theme: {
    primaryColor: '#3951a3',
    infoColor: '#3951a3',
    errorColor: '#a34141'
  },
});


