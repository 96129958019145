import axios from "axios";
import { setAlert } from "./alert";
import { API_BACKEND, API_URL, GET_SPECIFIC_DAY, GET_SPECIFIC_DAY_ERROR } from "./types";
import {
  GET_TRUCK,
  CREATE_TRUCK,
  TRUCK_ERROR,
  DELETE_TRUCK,
  GET_SPECIFIC_TRUCK,
  ASSIGN_DRIVER_TO_TRUCK,
  ASSIGN_DRIVER_TO_TRUCK_ERROR,
  GET_HISTORY_ASSIGNED_DRIVER,
  GET_HISTORY_ASSIGNED_DRIVER_ERROR,
  DELETE_DRIVER_TO_TRUCK,
  GET_SENSORY_DATA_BY_DATE,
  GET_GPS_DATE,
  GET_GPS_DATE_ERROR,
  GET_GPS_DATA_BY_DATE,
  GET_GPS_DATA_BY_DATE_ERROR,
  GET_SPECIFIC_SENSORY_DATA,
  GET_SPECIFIC_SENSORY_DATA_ERROR,
  DELETE_ALL_SENSORY_DATA,
  DELETE_ALL_SENSORY_DATA_ERROR,
  GET_VIOLATIONS_DATA,
  GET_VIOLATIONS_DATA_ERROR,
  UPDATE_TRUCK_DETAILS,
  GET_STATIC_TRUCK_DURATION,
  GET_STATIC_TRUCK_DURATION_ERROR,
  UPDATE_TRUCK,
  UPDATE_TRUCK_ERROR,
  GET_VEHICLE_ID,
  GET_USER_ID
} from "./types";
import saveAs from "file-saver";
//Get trucks
export const getTrucks = () => async (dispatch) => {
  try {
    const res = await axios.get(API_URL + "/api/trucks/getTrucks");
    dispatch({
      type: GET_TRUCK,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TRUCK_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

//Create truck
export const createTruck = (vehicleType, plateNumber) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ vehicleType, plateNumber });
    const res = await axios.post(
      API_URL + "/api/trucks/createTruck",
      body,
      config
    );
    dispatch({
      type: CREATE_TRUCK,
      payload: res.data,
    });
    dispatch(setAlert("Success. Vehicle is created.", "success"));
    console.log(vehicleType, plateNumber);
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRUCK_ERROR,
      payload: {
        msg: errors,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

//Update Truck

/*export const updateTruck = (formData, id) => async (dispatch) => {
  try {
    const res = await axios.put(
      API_URL + `/api/trucks/updateExistingTruck/${id}`
    );
    dispatch({
      type: UPDATE_TRUCK_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Success. Truck is Updated.", "success"));
  } catch (err) {
    dispatch({
      type: TRUCK_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};*/

export const updateTruck = (formData, id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      API_URL + `/api/trucks/updateExistingTruck/${id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_TRUCK_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Success. Vehicle details are updated.", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRUCK_ERROR,
      payload: {
        msg: errors,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

//Delete truck
export const deleteTruck = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(API_URL + `/api/trucks/deleteTruck/${id}`);
    dispatch({
      type: DELETE_TRUCK,
      payload: res.data,
    });
    dispatch(setAlert("Success. Vehicle is deleted.", "success"));
  } catch (err) {
    dispatch({
      type: TRUCK_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

//Get specific truck
export const getSpecificTruck = (vehicleid) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
    },
  };
  try {
    const res = await axios.get(
      API_URL + `/api/trucks/getSpecificTruck/${vehicleid}`,config
    );
    dispatch({
      type: GET_SPECIFIC_TRUCK,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TRUCK_ERROR,
    });
  }
};

//Get sensory date
export const getSensoryDataByDate =
  (vehicleid, filterDate) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
      },
    };
    try {
      const res = await axios.get(
        API_URL +
          `/api/trucks/getSensoryDataByFilteredDate/${vehicleid}/${filterDate}`,config
      );
      dispatch({
        type: GET_SENSORY_DATA_BY_DATE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TRUCK_ERROR,
      });
    }
  };

//Get Specific Day
export const getSpecificDay = (vehicleid, date) => async (dispatch) => {
  try {
    const res = await axios.get(
      API_URL + `/api/trucks/getSpecificDay/${vehicleid}/${date}`
    );
    dispatch({
      type: GET_SPECIFIC_DAY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SPECIFIC_DAY_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

//Get Static duration per location
export const getStaticDuration = (vehicleid, date) => async (dispatch) => {
  try {
    const res = await axios.get(
      API_URL + `/api/trucks/durationPerDay/${vehicleid}/${date}`
    );
    dispatch({
      type: GET_STATIC_TRUCK_DURATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_STATIC_TRUCK_DURATION_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

//Get GPS date
export const getGpsDate = (vehicleid) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
    },
  };
  try {
    const res = await axios.get(API_URL + `/api/trucks/pathDate/${vehicleid}`, config);
    dispatch({
      type: GET_GPS_DATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_GPS_DATE_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};
//GetGPSDataByDate
export const getGpsDataByDate = (vehicleid, date) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
    },
  };
  try {
    console.log(date);
    const res = await axios.get(
      API_URL + `/api/trucks/pathByDay/${vehicleid}/${date}`,config
    );
    dispatch({
      type: GET_GPS_DATA_BY_DATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_GPS_DATA_BY_DATE_ERROR,
      payload: {
        msg: err.response.data.msg,
        status: err.response.statusText,
        type: err.response.status,
      },
    });
  }
};

// export const getMaxValSensoryData = (vehicleid) => async dispatch => {
//   try{
//     const res = await axios.get(API_URL+`/api/trucks/reports/${vehicleid}`);
//     dispatch({
//       type: GET_MAX_VAL_SENSORY_DATA,
//       payload: res.data
//     })
//   }catch(err){
//     dispatch({
//       type: GET_MAX_VAL_SENSORY_DATA_ERROR,
//       payload: {msg: err.response, status: err.response}
//     })
//   }
// }

//Assign driver to truck
export const assignDriverToTruck =
  (getVehicleId, first_name, last_name, company) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ first_name, last_name, company });

      const res = await axios.post(
        API_URL + `/api/trucks/assignDriver/${getVehicleId}`,
        body,
        config
      );
      dispatch({
        type: ASSIGN_DRIVER_TO_TRUCK,
        payload: res.data,
      });
      dispatch(setAlert("Success. Driver is now assigned.", "success"));
    } catch (err) {
      dispatch({
        type: ASSIGN_DRIVER_TO_TRUCK_ERROR,
        payload: {
          msg: err.response.data.msg,
          status: err.response.statusText,
          type: err.response.status,
        },
      });
    }
  };

export const getHistoryAssignedDrivers = () => async (dispatch) => {
  try {
    const res = await axios.get(API_URL + "/api/trucks/getAllAssignedDriver");
    dispatch({
      type: GET_HISTORY_ASSIGNED_DRIVER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_HISTORY_ASSIGNED_DRIVER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
//Get specific sensory data to validate
export const getSpecificSensoryData = (vehicle_id, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
    },
  };
  try {
    const res = await axios.get(
      API_URL + `/api/trucks/sensoryData/${vehicle_id}/${id}`,config
    );
    dispatch({
      type: GET_SPECIFIC_SENSORY_DATA,
      payload: res.data,
    });
  } catch (err) {
  }
};
//Update validate data
export const validateSensoryData =
  (vehicle_id, id, status_4) => async (dispatch) => {
    console.log(vehicle_id);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
        },
      };
      console.log(vehicle_id, id, status_4);
      const body = JSON.stringify({ status_4: status_4 });
      const res = await axios.put(
        API_URL + `/api/trucks/validate/${vehicle_id}/${id}`,
        body,
        config
      );
      dispatch({
        type: GET_SPECIFIC_SENSORY_DATA,
        payload: res.data,
      });
      dispatch(setAlert("Success. Data is validated.", "success"));
    } catch (err) {
      dispatch({
        type: GET_SPECIFIC_SENSORY_DATA_ERROR,
        payload: {
          msg: err.response.data.msg,
          status: err.response.statusText,
          type: err.response.status,
        },
      });
    }
  };

//Delete assigned driver
export const deleteAssignedDriver =
  (vehicle_id, assigned_driver_id) => async (dispatch) => {
    try {
      const res = await axios.delete(
        API_URL +
          `/api/trucks/deleteAssignedDriver/${vehicle_id}/${assigned_driver_id}`
      );
      dispatch({
        type: DELETE_DRIVER_TO_TRUCK,
        payload: res.data,
      });
      dispatch(setAlert("Success. Driver is now unassigned.", "success"));
    } catch (err) {
      dispatch(setAlert(`Error. ${err}`, "error"));
      dispatch({
        type: ASSIGN_DRIVER_TO_TRUCK_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };
//Erase all the sensory data from a specific truck
export const deleteSensoryData = (vehicle_id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      API_URL + `/api/trucks/deleteSensoryData/${vehicle_id}`
    );
    dispatch({
      type: DELETE_ALL_SENSORY_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: DELETE_ALL_SENSORY_DATA_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const violationsData = () => async (dispatch) => {
  try {
    const url = "https://api.driverph.com/api/overspeedingML";
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        dispatch({
          type: GET_VIOLATIONS_DATA,
          payload: data,
        });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  } catch (err) {
    dispatch({
      type: GET_VIOLATIONS_DATA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const generateReports = (vehicle_id, id) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
      },
    };
    axios
      .get(API_URL + `/api/trucks/generateReport/${vehicle_id}/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "report.pdf");
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
  }
};

//update truck

//Download truck image
// export const downloadTruckImage =
//   ({ vehicleFinalid, camera_angle, getYear, getMonth, getDate, getHours }) =>
//   async dispatch => {
//     const url = API_URL+`/api/images/download/${vehicleFinalid}/${camera_angle}/${getYear}/${getMonth}/${getDate}/${getHours}`;
//     const method = 'GET';
//     console.log(
//       vehicleFinalid,
//       camera_angle,
//       getYear,
//       getMonth,
//       getDate,
//       getHours
//     );
//     try {
//       axios
//         .request({ url, method, responseType: 'blob' })
//         .then(({ data }) => {
//           const downloadUrl = window.URL.createObjectURL(new Blob([data]));
//           const link = document.createElement('a');
//           link.href = downloadUrl;
//           link.setAttribute('download', 'file-images.zip');
//           document.body.appendChild(link);
//           link.click();
//           link.remove();

//           dispatch({
//             type: DOWNLOAD_IMAGE,
//             payload: data.data,
//           });
//           dispatch(setAlert('Download sucessfully.', 'success'));
//         })
//         .catch(err => {
//           dispatch(setAlert('No such file or directory.', 'error'));
//           dispatch({
//             type: DOWNLOAD_ERROR,
//             payload: {
//               msg: err.response.statusText,
//               status: err.response.status,
//             },
//           });
//         });
//     } catch (err) {
//       console.log(err.message);
//     }
//   };