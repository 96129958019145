import { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

//css
import "../../temp_iot/src/components/css/App.css";
import "antd/dist/antd.css";
import pasadalogo from "./components/assets/pasadalogo.png";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import LoginExample from "../../temp_iot/src/components/auth/Login";
import Review from "../../temp_iot/src/components/component/Review";

//components
import Driver from "../../temp_iot/src/components/pages/Driver";
import Monitor from "../../temp_iot/src/components/pages/Monitor";
import Truck from "../../temp_iot/src/components/pages/Truck";
import Sidebar from "../../temp_iot/src/components/component/Sidebar";
import Profile from "../../temp_iot/src/components/pages/Profile";
import MapHistory from "../../temp_iot/src/components/pages/MapHistory";
import CreateProfile from "../../temp_iot/src/components/component/CreateProfile";
import NotFoundPage from "../../temp_iot/src/components/pages/NotFoundPage";
import EditProfile from "../../temp_iot/src/components/component/EditProfile";
import RegisterUser from "../../temp_iot/src/components/pages/User";
import DashboardBeta from "../../temp_iot/src/components/pages/DashboardBeta";
import TrackAll from "../../temp_iot/src/components/pages/TrackAll";
import { loadUser } from "../../temp_iot/src/actions/auth";
import setAuthToken from "../../temp_iot/src/utils/setAuthToken";
import PrivateRoute from "../../temp_iot/src/components/routing/PrivateRoute";
import Dashboard from "../../temp_iot/src/components/pages/Dashboard";
import { Layout, Avatar, Image, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
const { Header, Content, Sider, Footer } = Layout;

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = ({ loadUser, auth: { loggedInuser } }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const color1 = "#3951a3";
  const color2 = "#ffffff";

  // const menu = (
  //   // <Menu>
  //   //   <Menu.Item key="0">
  //   //     <Link to="/profile" style={{ fontFamily: "Inter, sans-serif", fontSize: "15px"}}>
  //   //       View Profile
  //   //     </Link>
  //   //   </Menu.Item>
  //   // </Menu>
  // );
  return (
    <Router>
      <Fragment>
        <div className="app">
          <Switch>
          <Route exact path="/" component={LoginExample} />

            <Layout style={{ minHeight: "100vh" }}>
              {/*<Sider collapsible collapsed={collapsed} onCollapse={onCollapse} theme="light">*/}
              {/*  <Sidebar />*/}
              {/*</Sider>*/}
              <Layout className="site-layout">
                <Header
                  className="site-layout-background"
                  style={{ padding: 0, marginLeft: 0, background: `linear-gradient(1.35turn, ${color1} 58%,${color2} 65%)`,}}
                > 
                  {" "}
                  <ul
                    style={{
                      float: "right",
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginRight: "12px",
                      textDecoration: "none",
                    }}
                  >
                    <li
                      style={{ textDecoration: "none", listStyleType: "none" }}
                    >
                    </li>
                    <li
                      style={{ textDecoration: "none", listStyleType: "none" }}
                    >
                      {/*<Dropdown overlay={menu} trigger={["click"]}>*/}
                      {/*</Dropdown>*/}
                      <img
                          src={pasadalogo}
                          style={{
                            float: "right",
                            width: "27%",
                            height: "100%",
                            paddingTop: "9px",
                            // marginBottom: "10px",
                          }}
                      ></img>
                    </li>
                  </ul>{" "}
                  {/* <p style={{fontWeight: "bold", marginLeft: 20, fontSize: 20}}>{headerTitle}</p> */}
                </Header>
                <Content style={{ margin: "15px 16px" }}>
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360, }}
                  >
                    <PrivateRoute exact
                      path="/monitor/:vehicle_id"
                    component={Dashboard}
                    />
                    <PrivateRoute exact 
                      path="/vehicle" 
                      component={Truck} 
                    />
                    <PrivateRoute exact
                      path="/driver"
                    component={Driver}
                    />
                    
                    {/*<PrivateRoute exact */}
                    {/*  path="/profile" */}
                    {/*  component={Profile} */}
                    {/*/>*/}
                    {/*<PrivateRoute exact */}
                    {/*  path="/user" */}
                    {/*  component={RegisterUser} */}
                    {/*/>*/}
                    <PrivateRoute exact 
                      path="/trackall" 
                      component={TrackAll} 
                    />
                    <PrivateRoute exact 
                      path="/monitor/:vehicle_id/map-history" 
                      component={MapHistory}
                    />

                    {/*<PrivateRoute*/}
                    {/*  exact*/}
                    {/*  path="/create-profile"*/}
                    {/*  component={CreateProfile}*/}
                    {/*/>*/}
                    {/*<PrivateRoute*/}
                    {/*  exact*/}
                    {/*  path="/edit-profile"*/}
                    {/*  component={EditProfile}*/}
                    {/*/>*/}
                    <PrivateRoute
                      exact
                      path="/monitor/:vehicle_id/:id/review"
                      component={Review}
                    />
                    {/*<PrivateRoute*/}
                    {/*  exact*/}
                    {/*  path="/monitor/:vehicle_id"*/}
                    {/*  component={Dashboard}*/}
                    {/*/>*/}
                    {/*<PrivateRoute*/}
                    {/*  exact*/}
                    {/*  path="/monitor/:vehicle_id/beta"*/}
                    {/*  component={DashboardBeta}*/}
                    {/*/>*/}
                  </div>
                </Content>
                <Footer
                  style={{
                    textAlign: "center",
                    backgroundColor: "#CDCED0",
                    color: "#000000",
                  }}
                >
                  PASADA - The Next Generation of Public Transportation. {" "}
                </Footer>
              </Layout>
            </Layout>
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </div>
      </Fragment>
    </Router>
  );
};
// }
App.propTypes = {
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { loadUser })(App);
