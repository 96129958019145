import axios from 'axios';
import {setAlert} from './alert';
import {API_BACKEND, API_URL} from './types';
import { GET_DRIVER, CREATE_DRIVER, DRIVER_ERROR, DELETE_DRIVER, UPDATE_DRIVER} from './types';

//Get drivers
export const getDrivers = () => async dispatch => {
    try{
        const res = await axios.get(API_URL+'/api/drivers/getDrivers');
        dispatch({
            type: GET_DRIVER,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: DRIVER_ERROR,
            payload: {msg: err.response.data.msg, status: err.response.statusText, type: err.response.status}
        });
    }
}

//Create driver
export const createDriver = (formData) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post(API_URL+'/api/drivers/createDriver', formData, config);
        dispatch({
            type: CREATE_DRIVER,
            payload: res.data
        });
        dispatch(setAlert('Success. Driver is created.', 'success'));
        
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')))
        }
        dispatch({
            type: DRIVER_ERROR,
            payload: {msg: errors, status: err.response.statusText, type: err.response.status}
        });
    }
}

//Delete driver
export const deleteDriver = id => async dispatch => {
    try{
        const res = await axios.delete(API_URL+`/api/drivers/deleteDriver/${id}`);
        dispatch({
            type: DELETE_DRIVER,
            payload: res.data
        });
        dispatch(setAlert('Success. Driver is deleted.', 'success'));
    }catch(err){
        dispatch({
            type: DRIVER_ERROR,
            payload: {msg: err.response.data.msg, status: err.response.statusText, type: err.response.status}
        });
    }
}

//Update driver
export const updateDriver = (formData,id) => async dispatch => {

    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const res = await axios.put(API_URL+`/api/drivers/updateDriver/${id}`, formData, config);
        dispatch({
            type: UPDATE_DRIVER,
            payload: res.data
        });
        dispatch(setAlert('Success. Driver is updated.', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
          }
        dispatch({
            type: DRIVER_ERROR,
            payload: {msg: errors, status: err.response.statusText, type: err.response.status}
        });
    }
}
