import { GET_DRIVER, DRIVER_ERROR, CREATE_DRIVER, DELETE_DRIVER, UPDATE_DRIVER} from "../actions/types";

const initialState = {
    driver: null,
    drivers: [],
    loading: true,
    error: {}
}

export const driverReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch(type){
    
        case GET_DRIVER:
            return {
                ...state,
                drivers: payload,
                loading: false
            }
        case DELETE_DRIVER:
        case UPDATE_DRIVER:
        case CREATE_DRIVER:
        case DRIVER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}
export default driverReducer;