import axios from 'axios';

import { API_URL } from './types';
import { setAlert } from './alert';

import{
    GET_REGISTERED_USERS,
    GET_REGISTERED_USERS_ERROR,
    DELETE_SPECIFIC_USER,
    DELETE_SPECIFIC_USER_ERROR,
    UPDATE_SPECIFIC_USER,
    UPDATE_SPECIFIC_USER_ERROR,
    REGISTER_SUCESS,
    REGISTER_FAIL,
} from './types';


//Register User
export const register =
  ({ firstName, lastName, email, role, password }) =>
  async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ firstName, lastName, email, role, password });
    try {
      const res = await axios.post(API_URL+'/api/users/createUser', body, config);
      dispatch({
        type: REGISTER_SUCESS,
        payload: res.data,
      });
      dispatch(setAlert('Success. User is created.', 'success'));
    } catch (err) {
      const errors = err.response.data.errors;
     
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: REGISTER_FAIL,
        payload: {msg: errors, status: err.response.statusText, type: err.response.status}
      });
    }
  };

//Get all users
export const getRegisteredUsers = () => async dispatch => {
  try {
    const res = await axios.get(API_URL+'/api/users/getUsers');
    dispatch({
      type: GET_REGISTERED_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_REGISTERED_USERS_ERROR,
      payload: {msg: err.response.data.msg, status: err.response.statusText, type: err.response.status}
    });
    
  }
};

//Delete specific user
export const deleteSpecificUser = email_address => async dispatch => {
  try {
    const res = await axios.delete(API_URL+`/api/users/deleteUser/${email_address}`);
    dispatch({
      type: DELETE_SPECIFIC_USER,
      payload: res.data,
    });
    dispatch(setAlert('Success. User is deleted.', 'success'));
  } catch (err) {
    dispatch({
      type: DELETE_SPECIFIC_USER_ERROR,
      payload: {msg: err.response.data.msg, status: err.response.statusText, type: err.response.status}
    });
  }
};

//Update speicific user 
export const updateSpecificUser = (firstName, lastName, email, password, role, emailToUpdate) => async dispatch => {
  try{
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
 
    const body = JSON.stringify({ firstName, lastName, email, role, password });
    const res = await axios.put(API_URL+`/api/users/updateUser/${emailToUpdate}`, body, config);
    dispatch({
      type: UPDATE_SPECIFIC_USER,
      payload: res.data
    });

    dispatch(setAlert('Success. User is updated.', 'success'));
  }catch(err){
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: UPDATE_SPECIFIC_USER_ERROR,
      payload: {msg: errors, status: err.response.statusText, type: err.response.status}
    });
  }
}