import { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../../../temp_iot/src/actions/auth";
import { Menu } from "antd";

import { FiLogOut, FiUsers} from "react-icons/fi";
import { RiLineChartLine, RiRoadMapLine } from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import { IoBusOutline, IoHomeOutline, IoSettingsOutline } from "react-icons/io5";

import pasadalogo from "../assets/pasadalogo-sidebar.png";
import "../../../../temp_iot/src/components/css/Dashboard.css";
// import { MdNoEncryption } from "react-icons/md";
// import { dark } from "@mui/material/styles/createPalette";

// const styleMenu = {
//   color: "black",
// };

const { SubMenu } = Menu;
const Sidebar = () => {
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };
  return (
    <div>
      <div className="logo">
        <img
          src={pasadalogo}
          style={{
            width: "75%",
            height: "100%",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        ></img>
      </div>
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        style={{ fontFamily: "Inter, sans-serif" }}
        theme="light"

      >
        <Menu.Item key="1" icon={<RiLineChartLine />}>
          <Link to="/monitor" onClick={handleBack}>
            Monitor
          </Link>
        </Menu.Item>
        {/*  <Menu.Item key="3" icon={<IoBusOutline />} style={styleMenu}>*/}
        {/*  <Link to="/vehicle" style={styleMenu}>*/}
        {/*    Vehicles*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item key="2" icon={<HiOutlineUserGroup />} style={styleMenu}>*/}
        {/*  <Link to="/driver" style={styleMenu}>*/}
        {/*    Drivers*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        
        {/*{(loggedInuser && loggedInuser.user.role_id === 1) ||*/}
        {/*(loggedInuser && loggedInuser.user.role_id === 3) ? (*/}
        {/*  <Fragment>*/}
        {/*    <Menu.Item key="4" icon={<FiUsers />} style={styleMenu}>*/}
        {/*      <Link to="/user" style={styleMenu}>*/}
        {/*        Users*/}
        {/*      </Link>*/}
        {/*    </Menu.Item>*/}
        {/*  </Fragment>*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
        <Fragment>
            <Menu.Item key="5" icon={<RiRoadMapLine />}>
              <Link to="/trackall">
                Track All
              </Link>
            </Menu.Item>
          </Fragment>
        <Menu.Item key="6" icon={<IoHomeOutline />}>
          <a href="https://pasadaph.com/">
            Home
          </a>
        </Menu.Item>

        {/*<SubMenu*/}
        {/*  key="sub1"*/}
        {/*  icon={<IoSettingsOutline />}*/}
        {/*  title="Settings"*/}
        {/*  style={styleMenu}*/}
        {/*>*/}
        {/*  <Menu.Item key="7" icon={<AiOutlineUser />} style={styleMenu}>*/}
        {/*    <Link to="/profile"*/}
        {/*    style={styleMenu}*/}
        {/*    >*/}
        {/*      Profile*/}
        {/*    </Link>*/}
        {/*  </Menu.Item>*/}
          {/* <Menu.Item key="8" icon={<FiLogOut />} style={styleMenu}>
            <a onClick={logout}
            style={styleMenu}
            icon={<FiLogOut />}
            >
              Logout
            </a>
          </Menu.Item> */}
        {/*</SubMenu>*/}
      </Menu>
    </div>
  );
};
export default Sidebar;
