import React, { Fragment, useState } from 'react';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Alert from '../../utils/Alert';
import { Form, Input, Button, Col, Row, Space, Typography} from 'antd';
import '../css/Login.css';
import logo from '../assets/pasadalogo.png';
import dost from '../assets/partners/DOST.png';
import tip from '../assets/partners/TIP.png';

import DocumentMeta from 'react-document-meta'

const LoginExample = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;
  const {Link} = Typography;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async e => {
    login(email, password);
  };
  //Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/monitor' />;
  }

  const styleLogo = {
    width: '180px',
    marginBottom: '10px',
    marginTop: '20px',
    display: 'flex',
    alignSelf: "center",
    justifyContent: 'center',
    alignItems: 'center'
  }

  const stylePartners = {
    width: '80px',
    marginBottom: '10px',
    display: 'flex',
  }

  const styleFormInput = {
    width: '400px',
    height: '35px',
  }

  const styleRow = {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const styleButton = {
    width: '400px',
    height: '45px',
    color: 'white',
    backgroundColor: '#3A519F',
    marginTop: '20px',
    border: 'none',
  }

  //META
  const meta = {
    title: 'PASADA IoT',
    description: 'Pasada login',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'login,pasada,pasadaph'
      }
    }
  }

  return (
    <Fragment>
      <DocumentMeta {...meta}>

      <div className='form'>
        <div className='form-container'>
          <div className='form-content-left'>
            <div className='tagline'>The Next Generation of Public Transportation</div>
            <div>
              <p style={{fontSize:18}}>IN PARTNERSHIP WITH</p>
              <Row gutter={[16, 16]} style={styleRow}>
                <Space align='center'>
                <Col span={5}>
                  <Link href="https://www.dost.gov.ph/">
                    <img
                    src={dost}
                    style={stylePartners}
                    alt="DOST Logo"
                  ></img></Link>
                </Col>
                <Col span={5}>
                <Link href="https://www.tip.edu.ph/">
                  <img
                    src={tip}
                    style={stylePartners}
                    alt="TIP Logo"
                  ></img>
                </Link>
                </Col>
                </Space>
              </Row>
            </div>
          </div>

          <div className='form-content-right'>
            <Form
              action='#'
              onFinish={e => onSubmit(e)}
            >
              <div className='img-container'>
                <img
                  src={logo}
                  style={styleLogo}
                  alt="PASADAPH Logo"
                ></img>
              </div>

              <h1> Sign In</h1>

              <div style={{ width: '400px' }}>
                <Alert />
              </div>
              <p>Email address</p>
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email address',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder='juandelacruz@example.com'
                  type='email'
                  id='email'
                  name='email'
                  value={email}
                  onChange={e => onChange(e)}
                  style={styleFormInput}
                />
              </Form.Item>
              <p>Password</p>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder='Enter your password'
                  type='password'
                  id='password'
                  name='password'
                  value={password}
                  onChange={e => onChange(e)}
                  style={styleFormInput}
                />
              </Form.Item>

              <Button
                type='primary'
                style={styleButton}
                htmlType='submit'
              >
                Log In
              </Button>
            </Form>
          </div>
        </div>
      </div>
      </DocumentMeta>
    </Fragment>
  );
};

LoginExample.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LoginExample);
