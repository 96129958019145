import { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profile';
import Spinner from '../../utils/Spinner';
import DocumentMeta from 'react-document-meta';


import {
  Card,
  Button,
  PageHeader,
  Row,
  Col,
  Descriptions,
  Empty,
} from 'antd';

//META
const meta = {
  title: 'PASADAPH | Profile',
  description: 'My profile',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'profile,driverph profile'
    }
  }
}

const Profile = ({
  getCurrentProfile,
  auth: { loggedInuser },
  profile: { profile, loading },
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <DocumentMeta {...meta}>
    <Fragment>
      {profile === null ? (
        <>
          <PageHeader
            className='site-page-header page-custom-header'
            title='Profile'
            // subTitle='| Setup My Account'
            extra={
              <Link to='/create-profile'>
                <Button type='primary'>Setup My Account</Button>
              </Link>
            }
          />
          <Empty/>
        </>
      ) : (
        <>
          <PageHeader
            className='site-page-header page-custom-header'
            title='Profile'
            // subTitle='| My Profile'
          />
          <Row>
            <Col span={24}>
              <Fragment>
                <Card
                  style={{
                    boxShadow: '0 3px 6px 0 #0000001a',
                    borderRadius: '6px',
                  }}
                  block='true'
                >
                  <Descriptions
                    title='Personal Information'
                    layout='vertical'
                    bordered
                    extra={
                      <Link to='/edit-profile'>
                        <Button type='primary'>Edit Profile</Button>
                      </Link>
                    }
                    style={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    <Descriptions.Item label='Name'>
                      {`${loggedInuser && loggedInuser.firstName} ${
                        loggedInuser && loggedInuser.lastName
                      }`}
                    </Descriptions.Item>
                    <Descriptions.Item label='Role'>
                      {loggedInuser && loggedInuser.role}
                    </Descriptions.Item>
                    <Descriptions.Item label='Company'>
                      {profile && profile.company}
                    </Descriptions.Item>
                    <Descriptions.Item label='Location'>
                      {profile && profile.location}
                    </Descriptions.Item>
                  </Descriptions>
                  {profile.social === undefined ? (
                    <Fragment></Fragment>
                  ) : (
                    <Fragment>
                      <Descriptions
                        title='Social Information'
                        style={{
                          marginTop: '15px',
                          fontFamily: 'Inter, sans-serif',
                        }}
                        layout='vertical'
                        bordered
                      >
                        <Descriptions.Item label='Facebook' span={2}>
                          {profile && profile.social.facebook}
                        </Descriptions.Item>
                        <Descriptions.Item label='Twitter'>
                          {profile && profile.social.twitter}
                        </Descriptions.Item>
                        <Descriptions.Item label='Linkedin'>
                          {profile && profile.social.linkedin}
                        </Descriptions.Item>
                        <Descriptions.Item label='Instagram'>
                          {profile && profile.social.instagram}
                        </Descriptions.Item>
                      </Descriptions>
                    </Fragment>
                  )}
                </Card>
              </Fragment>
            </Col>
          </Row>
        </>
      )}
    </Fragment>
    </DocumentMeta>
  );
};

Profile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});
export default connect(mapStateToProps, { getCurrentProfile })(Profile);
