
import { Spin} from 'antd';

// const spin = {
//     margin: '120px 0',
//     marginBottom: '20px',
//     padding: '30px 50px',
//     textAlign: 'center',
//     borderRadius: '4px'
// }

export default () => (
       <div className="spin">
           <Spin size="large" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10em'}}/>
       </div>
);
    



