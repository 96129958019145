import { useState, Fragment, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import Alert from '../../utils/Alert';
import { PageHeader, Card, Form, Input, Divider, Button } from 'antd';

const EditProfile = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
}) => {
  const [formData, setFormData] = useState({
    company: '',
    location: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
  });
  useEffect(() => {
    getCurrentProfile();

    setFormData({
      company: loading || !profile.company ? '' : profile.company,
      location: loading || !profile.location ? '' : profile.location,
      facebook: loading || !profile.social ? '' : profile.social.facebook,
      twitter: loading || !profile.social ? '' : profile.social.twitter,
      linkedin: loading || !profile.social ? '' : profile.social.linkedin,
      instagram: loading || !profile.social ? '' : profile.social.instagram,
    });
  }, [loading]);
  const { company, location, facebook, twitter, linkedin, instagram } =
    formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    createProfile(formData, history, true);
  };
  return (
    <Fragment>
      <PageHeader
        className='site-page-header page-custom-header'
        title='Edit Profile'
        subTitle='| Make sure that your information is correct.'
    
      ></PageHeader>

      <Card style={{ width: '600px'}} >
        <Alert />
        <Form
          layout='vertical'
          labelCol={{ span: 4 }}
          onFinish={e => onSubmit(e)}
          style={{ fontFamily: 'Inter, sans-serif' }}
        >
          <Divider style={{fontSize: "17px",  color: '#495172', fontWeight: "bold"}}>Personal Information</Divider>
          <Form.Item label='Company' name="company"  rules={[
          {
            required: true,
            message: 'Please input your company.',
          },
        ]}>
            <Input
              placeholder='Enter your current company'
              name='company'
              value={company}
              onChange={e => onChange(e)}
              style={{ width: '550px' }}
            />
          </Form.Item>
          <Form.Item label='Location' name="location"  rules={[
          {
            required: true,
            message: 'Please input your current location.',
          },
        ]}>
            <Input
              placeholder='Enter your current location'
              name='location'
              value={location}
              onChange={e => onChange(e)}
              style={{ width: '550px' }}
            />
          </Form.Item>
          <Divider style={{fontSize: "17px",  color: '#495172', fontWeight: "bold"}}>Optional</Divider>
          <Form.Item label='Facebook'>
            <Input
              placeholder='Facebook url'
              name='facebook'
              value={facebook}
              onChange={e => onChange(e)}
              style={{ width: '550px' }}
            />
          </Form.Item>
          <Form.Item label='Twitter'>
            <Input
              placeholder='Twitter url'
              name='twitter'
              value={twitter}
              onChange={e => onChange(e)}
              style={{ width: '550px' }}
            />
          </Form.Item>
          <Form.Item label='Linkedin'>
            <Input
              placeholder='Linkedin url'
              name='linkedin'
              value={linkedin}
              onChange={e => onChange(e)}
              style={{ width: '550px' }}
            />
          </Form.Item>
          <Form.Item label='Instagram'>
            <Input
              placeholder='Instagram url'
              name='instagram'
              value={instagram}
              onChange={e => onChange(e)}
              style={{ width: '550px' }}
            />
          </Form.Item>
          <Button
            type='primary'
            style={{ marginBottom: '10px', height: '35px' }}
            htmlType='submit'
            block
          >
            Submit
          </Button>
          <Link to='/profile'>
            <Button
              type='default'
              style={{ height: '35px', width: '550px' }}
              block
            >
              Go back
            </Button>
          </Link>
        </Form>
      </Card>
    </Fragment>
  );
};

EditProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(EditProfile)
);
