import { useState } from "react";

import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Polyline,
  Marker,
  InfoWindow,
  TrafficLayer,
} from "react-google-maps";
import BusImg from "../assets/busMarker.png";
import trialImg from "../assets/truckMarker.png";
import JeepneyImg from "../assets/jeepneyMarker.png";

const GoogleMapAPI = (props) => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(true);
  const markerPosition = {
    lat: 14.62613552813322,
    lng: 121.06161758105758
  };
  let trialLoc = props.dataStatic;

  let trackerObject = props.trackAll;

  console.log("Tracker obj");
  console.log(trackerObject);

  return (
    <GoogleMap
      defaultZoom={props.defaultZoom}
      clickableIcons
      options={{
        streetViewControl: true,
      }}
      zoom={props.zoom}
      defaultCenter={props.center}
      center={props.center}
    >
      <TrafficLayer autoUpdate />
      <Polyline
        path={props.path}
        options={{
          geodesic: true,
          strokeColor: "#669DF6",
          strokeOpacity: 1.0,
          strokeWeight: 8,

        }}
      />

      {props.isMarkerShown && (
        <div>
          {(() => {
            if (props.vTypes === "Bus") {
              return (
                <Marker
                  position={props.center}
                  onClick={() => {
                    setInfoWindowOpen(true);
                  }}
                  icon={BusImg}
                >
                  {infoWindowOpen === true && (
                    <InfoWindow
                      onCloseClick={() => {
                        setInfoWindowOpen(false);
                      }}
                    >
                      <h5>
                        Plate Number: {props.truckPlateNum}
                        <br />
                        Coordinates: {props.latitute},{props.longitude}
                        <br />
                        Vehicle: {props.vTypes}
                      </h5>
                    </InfoWindow>
                  )}
                </Marker>
              );
            } else {
              return (
                <Marker
                  position={props.center}
                  onClick={() => {
                    setInfoWindowOpen(true);
                  }}
                  icon={JeepneyImg}
                >
                  {infoWindowOpen === true && (
                    <InfoWindow
                      onCloseClick={() => {
                        setInfoWindowOpen(false);
                      }}
                    >
                      <h5>
                        Plate Number: {props.truckPlateNum}
                        <br />
                        Coordinates: {props.latitute},{props.longitude}
                        <br />
                        Vehicle: {props.vTypes}
                      </h5>
                    </InfoWindow>
                  )}
                </Marker>
              );
            }
          })()}
        </div>
      )}

      {props.isMultipleMarker &&
        trialLoc.map((marker, index) => (
          <div>
            {(() => {
              if (props.vTypes === "Bus") {
                return (
                  <Marker
                    key={index} // Need to be unique
                    position={marker.position}
                    onClick={() => {
                      setInfoWindowOpen(true);
                    }}
                    icon={BusImg}
                  >
                    {infoWindowOpen === true && (
                      <InfoWindow
                        onCloseClick={() => {
                          setInfoWindowOpen(false);
                        }}
                      >
                        <h5>
                          Coordinates: {marker.position.lat},
                          {marker.position.lng}
                          <br />
                          Total Hours: {marker.totalHours}
                        </h5>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              } else {
                return (
                  <Marker
                    key={index} // Need to be unique
                    position={marker.position}
                    onClick={() => {
                      setInfoWindowOpen(true);
                    }}
                    icon={JeepneyImg}
                  >
                    {infoWindowOpen === true && (
                      <InfoWindow
                        onCloseClick={() => {
                          setInfoWindowOpen(false);
                        }}
                      >
                        <h5>
                          Coordinates: {marker.position.lat},
                          {marker.position.lng}
                          <br />
                          Total Hours: {marker.totalHours}
                        </h5>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              }
            })()}
          </div>
        ))}

      {props.trackAllVehicle &&
        trackerObject.map((marker, index) => (
          <div>
            {(() => {
              if (marker.vehicle_type === "Jeepney") {
                return (
                  <Marker
                    key={index} // Need to be unique
                    position={marker.gps}
                    onClick={() => {
                      setInfoWindowOpen(true);
                    }}
                    icon={JeepneyImg}
                  >
                    {infoWindowOpen === true && (
                      <InfoWindow
                        onCloseClick={() => {
                          setInfoWindowOpen(false);
                        }}
                      >
                        <h5>
                          Coordinates: {marker.latitute},{marker.longitude}
                          <br />
                          Plate Number: {marker.plate_number}
                          <br />
                          Date: {marker.date}
                          <br />
                          Vehicle Type: {marker.vehicle_type}
                        </h5>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              } else if (marker.vehicle_type === "Bus") {
                return (
                  <Marker
                    key={index} // Need to be unique
                    position={marker.gps}
                    onClick={() => {
                      setInfoWindowOpen(true);
                    }}
                    icon={BusImg}
                  >
                    {infoWindowOpen === true && (
                      <InfoWindow
                        onCloseClick={() => {
                          setInfoWindowOpen(false);
                        }}
                      >
                        <h5>
                          Coordinates: {marker.latitute},{marker.longitude}
                          <br />
                          Plate Number: {marker.plate_number}
                          <br />
                          Date: {marker.date}
                          <br />
                          Vehicle Type: {marker.vehicle_type}
                        </h5>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              }
            })()}
          </div>
        ))}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(GoogleMapAPI));
