import { combineReducers} from "redux";
import alert from './alert';
import auth from './auth';
import profile from './profile';
import driver from './driver';
import truck from './truck';
import user from './user';

export default combineReducers({
    alert,
    auth,
    user,
    profile,
    driver,
    truck,
});