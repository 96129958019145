
import { Line } from '@ant-design/charts';

const SensorGraph = props => {

  var config = {
    data: props.sensorData,
    padding: 'auto',
    xField: 'sensor_date',
    yField: `${props.yfield}`,
    xAxis: { tickCount: 5 },
 
  };

  return (
    <Line
      {...config}
      style={{
        width: '100%',
        height: '300px',
        border: 'none',
        boxShadow: '0 3px 6px 0 #0000001a',
        borderRadius: '6px',
        padding: '20px',
        background: '#fff',
        marginTop: '15px',
      }}
    />
  );
};

export default SensorGraph;
