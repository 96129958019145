

import { Area } from '@ant-design/charts';

const SensorMultilineGraphAreaHistory = props => {

  const config = {
    data: props.data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    slider: {
      start: 0.1,
      end: 0.9,
    },
  };

  return <Area {...config} style={{
    width: '100%',
    height: '300px',
    border: 'none',
    boxShadow: '0 3px 6px 0 #0000001a',
    borderRadius: '6px',
    padding: '20px',
    background: '#fff',
    marginTop: '15px',
  }}/>;
};

export default SensorMultilineGraphAreaHistory;