
import { Alert as AlertFromAntd, message} from 'antd';
import PropTypes from 'prop-types'
import {connect} from 'react-redux';

const Alert = ({ alerts }) => 
alerts !== null && alerts.length > 0 && alerts.map(alert => (
    <AlertFromAntd style={{marginBottom: "5px", marginTop: "15px"}}message={alert.msg} type={alert.alertType} key={alert.id} showIcon/>
));


Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
