export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCESS = "REGISTER_SUCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const GET_DRIVER = "GET_DRIVER";
export const DRIVER_ERROR = "DRIVER_ERROR";
export const CREATE_DRIVER = "CREATE_DRIVER";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";

export const GET_TRUCK = "GET_TRUCK";
export const TRUCK_ERROR = "TRUCK_ERROR";
export const CREATE_TRUCK = "CREATE_TRUCK";
export const DELETE_TRUCK = "DELETE_TRUCK";
export const GET_SPECIFIC_TRUCK = "GET_SPECIFIC_TRUCK";
export const GET_STATIC_TRUCK_DURATION = "GET_STATIC_TRUCK_DURATION";
export const GET_STATIC_TRUCK_DURATION_ERROR =
  "GET_STATIC_TRUCK_DURATION_ERROR";
export const GET_HISTORY_ASSIGNED_DRIVER = "GET_HISTORY_ASSIGNED_DRIVER";
export const GET_HISTORY_ASSIGNED_DRIVER_ERROR =
  "GET_HISTORY_ASSIGNED_DRIVER_ERROR";

export const DOWNLOAD_IMAGE = "DOWNLOAD_IMAGE";
export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR";
export const GET_IMAGES = "GET_IMAGES";
export const GET_IMAGES_ERROR = "GET_IMAGES_ERROR";

export const ASSIGN_DRIVER_TO_TRUCK = "ASSIGN_DRIVER_TO_TRUCK";
export const ASSIGN_DRIVER_TO_TRUCK_ERROR = "ASSIGN_DRIVER_TO_TRUCK_ERROR";
export const DELETE_DRIVER_TO_TRUCK = "DELETE_DRIVER_TO_TRUCK";
export const UPDATE_TRUCK_DETAILS = "UPDATE_TRUCK_DETAILS";
export const UPDATE_TRUCK = "UPDATE_TRUCK";
export const UPDATE_TRUCK_ERROR = "UPDATE_TRUCK_ERROR";

export const GET_REGISTERED_USERS = "GET_REGISTERED_USERS";
export const GET_REGISTERED_USERS_ERROR = "GET_REGISTERED_USERS_ERROR";
export const DELETE_SPECIFIC_USER = "DELETE_SPECIFIC_USER";
export const DELETE_SPECIFIC_USER_ERROR = "DELETE_SPECIFIC_USER_ERROR";
export const UPDATE_SPECIFIC_USER = "UPDATE_SPECIFIC_USER";
export const UPDATE_SPECIFIC_USER_ERROR = "UPDATE_SPECIFIC_USER_ERROR";

export const GET_GPS_DATE = "GET_GPS_DATE";
export const GET_GPS_DATE_ERROR = "GET_GPS_DATE_ERROR";
export const GET_GPS_DATA_BY_DATE = "GET_GPS_DATA_BY_DATE";
export const GET_GPS_DATA_BY_DATE_ERROR = "GET_GPS_DATA_BY_DATE_ERROR";

export const GET_SENSORY_DATA_BY_DATE = "GET_SENSORY_DATA_BY_DATE";
export const GET_SPECIFIC_SENSORY_DATA = "GET_SPECIFIC_SENSORY_DATA";
export const DELETE_ALL_SENSORY_DATA = "DELETE_ALL_SENSORY_DATA";
export const DELETE_ALL_SENSORY_DATA_ERROR = "DELETE_ALL_SENSORY_DATA_ERROR";
export const GET_SPECIFIC_SENSORY_DATA_ERROR =
  "GET_SPECIFIC_SENSORY_DATA_ERROR";
export const GET_SPECIFIC_DAY = "GET_SPECIFIC_DAY";
export const GET_SPECIFIC_DAY_ERROR = "GET_SPECIFIC_DAY_ERROR";
export const GET_VIOLATIONS_DATA = "GET_VIOLATIONS_DATA";
export const GET_VIOLATIONS_DATA_ERROR = "GET_VIOLATIONS_DATA_ERROR";

export const GET_USER_ID = "GET_USER_ID";
export const GET_VEHICLE_ID = "GET_VEHICLE_ID";

//Trial
export const API_URL = "http://13.229.134.213:8000";
export const API_BACKEND = "https://api2.pasadaph.com";
//export const API_URL = "http://192.168.100.19:8000";
// export const API_URL = "http://192.168.38.23:8000";

export const GOOGLE_API_KEY = "AIzaSyDhjhpeBXDb5ebgO57l7-N5wtzzl97hzFM";
