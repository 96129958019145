import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSpecificUser } from "../../actions/user";
import { setAlert } from "../../actions/alert";
import Alert from "../../utils/Alert";
import { Button, Form, Modal, Input, Select } from "antd";
const { Option } = Select;

const UpdateUser = ({
  userFirstName,
  userLastName,
  userEmail,
  setAlert,
  updateSpecificUser,
}) => {
  const [role, setRole] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  //Trial
  const onRoleChange = (value) => {
    switch (value) {
      case "superuser":
        setRole(value);
        return;
      case "operator":
        setRole(value);
        return;
      case "driver":
        setRole(value);
        return;
    }
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onSubmit = async (e) => {
    if (password !== confirmPassword) {
      setAlert("Passwords do not match.", "error");
    } else {
      updateSpecificUser(firstName, lastName, email, password, role, userEmail);
      handleOk();
      // window.location.reload();
    }
  };

  return (
    <Fragment>
      <Button
        type="primary"
        style={{
          marginRight: "15px",
        }}
        onClick={showModal}
      >
        Edit
      </Button>

      <Modal
        title="Edit User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Back
          </Button>,
        ]}
      >
        <Alert />
        <Form layout="vertical" onFinish={(e) => onSubmit(e)}>
          <Form.Item
            label="First name"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Please input a first name.",
              },
            ]}
          >
            <Input
              style={{ width: "470px" }}
              placeholder={userFirstName}
              name="firstName"
              type="text"
              value={firstName}
              onChange={onFirstNameChange}
            />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Please input a lastname.",
              },
            ]}
          >
            <Input
              style={{ width: "470px" }}
              placeholder={userLastName}
              name="lastName"
              type="text"
              value={lastName}
              onChange={onLastNameChange}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input an email.",
              },
            ]}
          >
            <Input
              style={{ width: "470px" }}
              placeholder={userEmail}
              name="emailAddress"
              type="email"
              value={email}
              onChange={onEmailChange}
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Please select a role.",
              },
            ]}
          >
            <Select style={{ width: "470px" }} onChange={onRoleChange} block>
              <Option value="superuser">Super User</Option>
              <Option value="operator">Operator</Option>
              <Option value="driver">Driver</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input the password.",
              },
            ]}
          >
            <Input
              style={{ width: "470px" }}
              placeholder="Enter password"
              name="password"
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmpassword"
            rules={[
              {
                required: true,
                message: "Please input the confirm password.",
              },
            ]}
          >
            <Input
              style={{ width: "470px" }}
              placeholder="Enter password again"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
            />
          </Form.Item>

          <Button
            type="primary"
            style={{
              marginBottom: "10px",
              height: "35px",
              width: "470px",
            }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </Fragment>
  );
};

UpdateUser.propTypes = {
  setAlert: PropTypes.func.isRequired,
  updateSpecificUser: PropTypes.func.isRequired,
};
export default connect(null, { setAlert, updateSpecificUser })(UpdateUser);
