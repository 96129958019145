import {
  GET_TRUCK,
  TRUCK_ERROR,
  CREATE_TRUCK,
  DELETE_TRUCK,
  GET_SPECIFIC_TRUCK,
  DOWNLOAD_ERROR,
  DOWNLOAD_IMAGE,
  GET_IMAGES,
  GET_IMAGES_ERROR,
  ASSIGN_DRIVER_TO_TRUCK,
  ASSIGN_DRIVER_TO_TRUCK_ERROR,
  GET_HISTORY_ASSIGNED_DRIVER,
  GET_HISTORY_ASSIGNED_DRIVER_ERROR,
  DELETE_DRIVER_TO_TRUCK,
  GET_SENSORY_DATA_BY_DATE,
  GET_GPS_DATE,
  GET_GPS_DATE_ERROR,
  GET_GPS_DATA_BY_DATE,
  GET_GPS_DATA_BY_DATE_ERROR,
  DELETE_ALL_SENSORY_DATA,
  DELETE_ALL_SENSORY_DATA_ERROR,
  GET_SPECIFIC_SENSORY_DATA,
  GET_SPECIFIC_SENSORY_DATA_ERROR,
  GET_VIOLATIONS_DATA,
  GET_VIOLATIONS_DATA_ERROR,
  GET_SPECIFIC_DAY,
  GET_SPECIFIC_DAY_ERROR,
  UPDATE_TRUCK,
  UPDATE_TRUCK_ERROR,
  GET_USER_ID,
  GET_VEHICLE_ID
} from '../actions/types';

const initialState = {
  image: null,
  images: [],
  truck: null,
  dates: [],
  gps_data_by_date: [],
  sensorydata: null,
  violations: null,
  review_sensory_data: null,
  history_assigned_driver: [],
  trucks: [],
  loading: true,
  gps_loading: true,
  error: {},
};

export const truckReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TRUCK:
      return {
        ...state,
        trucks: payload,
        loading: false
    }
    case CREATE_TRUCK:
    case ASSIGN_DRIVER_TO_TRUCK:
    case DELETE_DRIVER_TO_TRUCK:
    case UPDATE_TRUCK:
    case DELETE_TRUCK:
    case GET_GPS_DATE:
      return{
        ...state,
        dates: payload,
        loading: false,
      }
    case GET_HISTORY_ASSIGNED_DRIVER:
      return{
        ...state,
        history_assigned_driver: payload,
        loading: false
      }

    case GET_GPS_DATA_BY_DATE:
      return{
        ...state,
        gps_data_by_date: payload,
        loading: false
      }
    case GET_SPECIFIC_SENSORY_DATA:
      return {
        ...state,
        review_sensory_data: payload,
        loading: false,
      }
    case GET_VIOLATIONS_DATA:
      return {
        ...state,
        violations: payload,
        loading: false
      }
    case DELETE_ALL_SENSORY_DATA:
      return {
        ...state,
        gps_loading: false
      }
    case GET_SPECIFIC_DAY:
    case GET_SENSORY_DATA_BY_DATE:
      return {
        ...state,
        sensorydata: payload,
        gps_loading: false,
      };
    case GET_SPECIFIC_TRUCK:
      return {
        ...state,
        truck: payload,
        loading: false,
      };

    case GET_IMAGES:
      return {
        ...state,
        images: payload,
        loading: false,
      }
    case DOWNLOAD_IMAGE:
      return {
        ...state,
        image: payload,
        loading: false,
      };
    case DOWNLOAD_ERROR:
    case GET_IMAGES_ERROR:
    case ASSIGN_DRIVER_TO_TRUCK_ERROR:
    case GET_SPECIFIC_SENSORY_DATA_ERROR:
    case GET_SPECIFIC_DAY_ERROR:
    case DELETE_ALL_SENSORY_DATA_ERROR:
    case GET_GPS_DATE_ERROR:
    case GET_GPS_DATA_BY_DATE_ERROR:
    case GET_VIOLATIONS_DATA_ERROR:
    case GET_HISTORY_ASSIGNED_DRIVER_ERROR:
    case UPDATE_TRUCK_ERROR:
    case TRUCK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_USER_ID:
      return{
        ...state,
        user_id: payload,
        loading: false
      }
    case GET_VEHICLE_ID:
      return{
        ...state,
        vehicle_id: payload,
        loading: false
      }
    default:
      return state;
  }
};

export default truckReducer;
