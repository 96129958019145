
import { Gauge } from '@ant-design/charts';
import '../../../../temp_iot/src/components/css/Speed.css';

const Speed = props => {
  var config = {
    percent: props.gpsSpeed,
    type: 'undefined',
    value: props.gpsSpeed,
    width: 700,
    height: 230,
    range: {
      ticks: [0, 1],
      color: ['l(0) 0:#F4664A 0.5:#FAAD14 1:#30BF78'],
    },
    indicator: {
      pointer: { style: { stroke: '#D0D0D0' } },
      pin: { style: { stroke: '#D0D0D0' } },
    },

    style: {
      fill: 'red',
      fillOpacity: 0.5,
      stroke: 'black',
      lineWidth: 1,
      lineDash: [4, 5],
      strokeOpacity: 0.7,
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 5,
      shadowOffsetY: 5,
      cursor: 'pointer',
    },

    statistic: {
      title: {
        formatter: ({percent}) => {
          return `${percent} kp/h`
        }
      },
      content: {
        style: {
          fontSize: '25px',
          lineHeight: '36px',

          fontWeight: 'bold',
        },
        formatter: function formatter() {
          return props.speed;
        },
      },
    },
  };
  return <Gauge {...config} />;
};

export default Speed;
