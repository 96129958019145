import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Input, Modal, Button} from 'antd';
import { createDriver } from '../../actions/driver';
import { PlusOutlined } from '@ant-design/icons';
import Alert from '../../utils/Alert';

const DriverActions = ({ createDriver }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    age: '',
    company: '',
  });

  const { first_name, middle_name, last_name, age, company } = formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    createDriver(formData);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  return (
    <Fragment>
        <Button
                  type='primary'
                  style={{
                    float: 'right',
                  }}
                  onClick={showModal}
                  icon={<PlusOutlined />}
                >
                  Add Driver
                </Button>
      <Modal
                  title='Add Driver'
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={[
                    <Button key='back' onClick={handleCancel}>
                      Back
                    </Button>,
                  ]}
                >
      <Alert />
      <Form
        layout='vertical'
        onFinish={e => onSubmit(e)}
        style={{ fontFamily: 'Inter, sans-serif' }}
      >
        <Form.Item label='First Name' name="firstname"  rules={[
          {
            required: true,
            message: 'Please input your first name.',
          },
        ]}>
          <Input
            style={{ width: '465px' }}
            placeholder="Enter driver's first name"
            name='first_name'
            value={first_name}
            onChange={e => onChange(e)}
          />
        </Form.Item>
        <Form.Item label='Middle Name' name="middlename"  rules={[
          {
            required: true,
            message: 'Please input your middle name.',
          },
        ]}>
          <Input
            style={{ width: '465px' }}
            placeholder="Enter driver's middle name"
            name='middle_name'
            value={middle_name}
            onChange={e => onChange(e)}
          />
        </Form.Item>
        <Form.Item label='Last Name' name="lastname"  rules={[
          {
            required: true,
            message: 'Please input your last name.',
          },
        ]}>
          <Input
            style={{ width: '465px' }}
            placeholder="Enter driver's last name"
            value={last_name}
            name='last_name'
            onChange={e => onChange(e)}
          />
        </Form.Item>
        <Form.Item label='Age' name="age"  rules={[
          {
            required: true,
            message: 'Please input your age.',
          },
        ]}>
          <Input
            style={{ width: '465px' }}
            placeholder="Enter driver's age"
            type='number'
            value={age}
            name='age'
            onChange={e => onChange(e)}
          />
        </Form.Item>
        <Form.Item label='Company' name="company"  rules={[
          {
            required: true,
            message: 'Please input your company.',
          },
        ]}>
          <Input
            style={{ width: '465px' }}
            placeholder="Enter driver's company"
            value={company}
            name='company'
            onChange={e => onChange(e)}
          />
        </Form.Item>
        <Button
          type='primary'
          style={{
            marginBottom: '10px',
            height: '35px',
            fontFamily: 'Inter, sans-serif',
          }}
          htmlType='submit'
          block
        >
          Submit
        </Button>
      </Form>
      </Modal>
    </Fragment>
  );
};

DriverActions.propTypes = {
  createDriver: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  driver: state.driver,
});

export default connect(mapStateToProps, { createDriver })(DriverActions);
