import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Button, Select } from 'antd';
import { createTruck } from '../../../../temp_iot/src/actions/truck';
import Alert from '../../../../temp_iot/src/utils/Alert';
const { Option } = Select;
const TruckActions = ({ createTruck }) => {

  const [vehicleType, setVehicleType] = useState('');
  const [plateNumber, setPlateNumber] = useState('');

  const onVehicleChange = event => {
    switch(event){
      case 'Jeepney':
        setVehicleType(event)
        return;
      case 'Bus':
        setVehicleType(event);
        return;
    }
  }

  const onSubmit = e => {
    createTruck(vehicleType, plateNumber);
  };
  
  return (
    <Fragment>
      <Alert />
      <Form layout='vertical' onFinish={e => onSubmit(e)}>
      
      <Form.Item label='Vehicle Type'  name="vehicleType"  rules={[
          {
            required: true,
            message: 'Please input a vehicle type.',
          },
        ]}>
          <Select style={{ width: '470px' }} onChange={onVehicleChange} block>
            <Option value='Jeepney'>Jeepney</Option>
            <Option value='Bus'>Bus</Option>
          </Select>
        </Form.Item>
        <Form.Item label='Plate number' name="platenumber"  rules={[
          {
            required: true,
            message: 'Please input the plate number.',
          },
        ]}>
          <Input
            style={{ width: '470px' }}
            placeholder='KOT605'
            value={plateNumber}
            name='plate_number'
            onChange={e => setPlateNumber(e.target.value)}
          />
        </Form.Item>
        <Button
          type='primary'
          style={{ marginBottom: '10px', height: '35px' }}
          htmlType='submit'
          block
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );
};

TruckActions.propTypes = {
  createTruck: PropTypes.func.isRequired,

};

export default connect(null, { createTruck })(TruckActions);
