import { useState, useEffect } from "react";
import { Col, Row, Button, Table, PageHeader, Tag, Space, Input } from "antd";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from "@ant-design/icons";
//Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { GOOGLE_API_KEY } from "../../../../temp_iot/src/actions/types";

import { getGpsDate } from "../../../../temp_iot/src/actions/truck";
import { getGpsDataByDate } from "../../../../temp_iot/src/actions/truck";
import { useParams, useHistory } from "react-router-dom";
import GoogleMap from "../../../../temp_iot/src/components/component/GoogleMap";
import { ArrowLeftOutlined } from "@ant-design/icons";

const MapHistory = ({
  getGpsDate,
  getGpsDataByDate,
  truck: { truck, dates, gps_data_by_date },
}) => {
  let params = useParams();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [longLat, setLongLat] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  const [gpsData, setGpsData] = useState([]);
  const [gpsCenter, setGpsCenter] = useState({
    lat: 14.62613552813322,
    lng: 121.06161758105758,
  });
  useEffect(() => {
    getGpsDate(params.vehicle_id);
  }, []);
  useEffect(() => {
    setGpsData(gps_data_by_date && gps_data_by_date);
    gps_data_by_date &&
      gps_data_by_date.slice(-1).map((item) => setGpsCenter(item));
  }, [gps_data_by_date]);
  const gpsDataByDateFunc = async (record) => {
    await getGpsDataByDate(params.vehicle_id, record.date);
  };
  const handleBack = () => {
    history.goBack();
  };
  let searchInput;
  
  //styles
  const styleFont = {
    fontFamily: "Inter, sans-serif",
  };

  const styleHeader = {
    padding: "7px 7px 7px 15px",
    fontFamily: "Inter, sans-serif",
    // background: "#EDF5FD",
    border: "none",
    marginBottom: "15px",
  };

  const styleButton = {
    width: "90px",
  };

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={(node) => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: "block" }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={styleButton}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleReset(clearFilters)}
  //           size="small"
  //           style={styleButton}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({ closeDropdown: false });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : "",
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // ...getColumnSearchProps("date"),
    },
    {
      title: "Action",
      dataIndex: "gps_data",
      key: "gps_data",
      render: (text, record) => {
        return [
          <Button type="link" onClick={() => gpsDataByDateFunc(record)}>
            View
          </Button>,
        ];
      },
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        subTitle="Vehicle's Location History"
        tags={<Tag color="cyan">Overall</Tag>}
        style={styleHeader}
        extra={[
          <Input placeholder="Type to search.." 
            allowClear 
            onSearch={(value) => {
              setSearchText(value);
            }} 
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            suffix={<SearchOutlined />} 
            style={{ width: 200, marginRight: 10 }} 
            />,
          <Button
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={handleBack}
          >
            Go Back
          </Button>,
        ]}
      />

      <Row gutter={16}>
        <Col span={8}>
          <Table
            columns={columns}
            dataSource={dates && dates}
            size="small"
            style={styleFont}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: 100,
              onChange:(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize)
              }
            }}
          />

        </Col>
        <Col span={16}>
          <GoogleMap
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              GOOGLE_API_KEY +
              "&libraries=geometry,drawing,places"
            }
            truckPlateNum={truck && truck.plate_number}
            vTypes={truck && truck.vehicle_type}
            longitude={longLat.lng}
            latitute={longLat.lat}
            defaultZoom={10}
            zoom={20}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `85vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            path={gpsData}
            center={gpsCenter}
            isMarkerShown
          />
        </Col>
      </Row>
    </div>
  );
};
MapHistory.propTypes = {
  getGpsDate: PropTypes.func.isRequired,
  getGpsDataByDate: PropTypes.func.isRequired,
  truck: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  truck: state.truck,
});
export default connect(mapStateToProps, { getGpsDate, getGpsDataByDate })(
  MapHistory
);
