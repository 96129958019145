import React from "react";
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "../../utils/Alert";
import { updateTruck } from "../../actions/truck";

import { Form, Input, Modal, Button, Select } from "antd";

const { Option } = Select;
const UpdateTruck = ({ 
  updateTruck, 
  plateNumber, 
  vehicleid 
}) => {
  const [formData, setFormData] = useState({
    plate_number: "",
    vehicle_type: "",
  });
  //asdas
  const { plate_number, vehicle_type } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onVehicleChange = (event) => {
    switch (event) {
      case "Jeepney":
        setFormData({ ...formData, vehicle_type: event });
        return;
      case "Bus":
        setFormData({ ...formData, vehicle_type: event });
        return;
    }
  };
  const onSubmit = (e) => {
    console.log("Submitted update vehicle form, vehicle id is: " + vehicleid);
    updateTruck(formData, vehicleid);
    console.log(formData);
    handleOk();
    // window.location.reload();
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Button
        type="primary"
        style={{
          marginLeft: "10px",
        }}
        onClick={showModal}
      >
        Edit
      </Button>
      <Modal
        title="Edit Truck"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Back
          </Button>,
        ]}
      >
        <Alert />
        <Form
          layout="vertical"
          onFinish={(e) => onSubmit(e)}
          style={{ fontFamily: "Inter, sans-serif" }}
        >
          <Form.Item label="Plate Number" name="platenum">
            <Input
              style={{ width: "465px" }}
              placeholder={plateNumber}
              name="plate_number"
              value={plate_number}
              onChange={(e) => onChange(e)}
            />
          </Form.Item>

          <Form.Item
            label="Vehicle Type"
            name="vehicletype"
            rules={[
              {
                required: true,
                message: "Please select a vehicle type.",
              },
            ]}
          >
            <Select
              style={{ width: "470px" }}
              name="vehicle_type"
              onChange={onVehicleChange}
              block
            >
              <Option value="Jeepney">Jeepney</Option>
              <Option value="Bus">Bus</Option>
            </Select>
          </Form.Item>

          <Button
            type="primary"
            style={{
              marginBottom: "10px",
              height: "35px",
              fontFamily: "Inter, sans-serif",
            }}
            htmlType="submit"
            block
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </Fragment>
  );
};

UpdateTruck.propTypes = {
  updateTruck: PropTypes.func.isRequired,
};

export default connect(null, { updateTruck })(UpdateTruck);
