import { Fragment, useState } from 'react';

import { register } from '../../actions/user';
import PropTypes from 'prop-types';
import Alert from '../../utils/Alert';
import { setAlert } from '../../actions/alert';
import { connect } from 'react-redux';
import { Form, Input, Button, Select } from 'antd';
// import {handleCancel} from '../../components/pages/User';
const { Option } = Select;

const RegisterUserActions = ({ setAlert, register }) => {
  const [role, setRole] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const onRoleChange = value => {
    switch (value) {
      case 'superuser':
        setRole(value);
        return;
      case 'operator':
        setRole(value);
        return;
      case 'driver':
        setRole(value);
    }
  };

  const onFirstNameChange = e => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = e => {
    setLastName(e.target.value);
  };

  const onEmailChange = e => {
    setEmail(e.target.value);
  };

  const onPasswordChange = e => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
  };
  

  const onSubmit = async e => {
    if (password !== confirmPassword) {
      setAlert('Passwords do not match.', 'error');
    } else {
      register({ firstName, lastName, email, role, password });
    }
  };

  return (
    <Fragment>
      <Alert />
      <Form layout='vertical' onFinish={e => onSubmit(e)}>
        <Form.Item label='First name' name="firstname"  rules={[
          {
            required: true,
            message: 'Please input a first name.',
          },
        ]}>
          <Input
            style={{ width: '470px' }}
            placeholder='Enter first name'
            name='firstName'
            type='text'
            value={firstName}
            onChange={onFirstNameChange}
          />
        </Form.Item>
        <Form.Item label='Last name' name="lastname"  rules={[
          {
            required: true,
            message: 'Please input a lastname.',
          },
        ]}>
          <Input
            style={{ width: '470px' }}
            placeholder='Enter last name'
            name='lastName'
            type='text'
            value={lastName}
            onChange={onLastNameChange}
          />
        </Form.Item>
        <Form.Item label='Email' name="email"  rules={[
          {
            required: true,
            message: 'Please input an email.',
          },
        ]}>
          <Input
            style={{ width: '470px' }}
            placeholder='Enter email address'
            name='emailAddress'
            type='email'
            value={email}
            onChange={onEmailChange}
          />
        </Form.Item>
        <Form.Item label='Role' name="role"  rules={[
          {
            required: true,
            message: 'Please input a role.',
          },
        ]}>
          <Select style={{ width: '470px' }} onChange={onRoleChange} block>
            <Option value='superuser'>Super User</Option>
            <Option value='operator'>Operator</Option>
            <Option value='driver'>Driver</Option>
          </Select>
        </Form.Item>
        <Form.Item label='Password' name="password"  rules={[
          {
            required: true,
            message: 'Please input the password.',
          },
        ]}>
          <Input
            style={{ width: '470px' }}
            placeholder='Enter password'
            name='password'
            type='password'
            value={password}
            onChange={onPasswordChange}
          />
        </Form.Item>
        <Form.Item label='Confirm Password' name="confirmpassword"  rules={[
          {
            required: true,
            message: 'Please input the confirm password.',
          },
        ]}>
          <Input
            style={{ width: '470px' }}
            placeholder='Enter password again'
            name='confirmPassword'
            type='password'
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
          />
        </Form.Item>

        <Button
          type='primary'
          style={{
            marginBottom: '10px',
            height: '35px',
            width: '470px',
          }}
          htmlType='submit'
          key='submit'
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );
};

RegisterUserActions.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default connect(null, { setAlert, register })(RegisterUserActions);
