
import { Card, Statistic, Row, Col,Divider, Popover } from 'antd';
const SensorCardForGyro = props => {
  return (
    <Popover content={props.content} title={props.legendTitle}>
      <Card
        className='card'
        cover={
          <main
            style={{
              height: '45px',
              width: '100%',
            }}
          >
            <h1 className='sensor-card-desc' style={{color: "#000",}}>
              {props.title}
              <Divider style={{ marginTop: '5px' }} />
            </h1>
          </main>
        }
      >
        <Row>
          <Col span={7}>
            <Statistic
              title={props.sectitle1}
              value={props.value1}
              valueStyle={{
                // color: '#464C6B',
                color: "#000",
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}
              precision={2}
              suffix={props.suffix}
              className='sensor-card-stats'
            />
          </Col>
          <Col span={7}>
            <Statistic
              title={props.sectitle2}
              value={props.value2}
              valueStyle={{
                // color: '#464C6B',
                color: "#000",
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}
              precision={2}
              suffix={props.suffix}
              className='sensor-card-stats'
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={props.sectitle3}
              value={props.value3}
              valueStyle={{
                // color: '#464C6B',
                color: "#000",
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}
              precision={2}
              suffix={props.suffix}
              className='sensor-card-stats'
            />
          </Col>
          <Col span={4}>{props.icon}</Col>
        </Row>
      </Card>
    </Popover>
  );
};

export default SensorCardForGyro;
