import { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  PageHeader,
  Empty,
  Input,
  notification,
  Col,
  Table,
  Button,
  Tag
} from "antd";
import BusImg from "../assets/busMarker.png";
import JeepneyImg from "../assets/jeepneyMarker.png";
import Card from "../../../../temp_iot/src/components/component/MonitorCard";
import { loadUser } from "../../../../temp_iot/src/actions/auth";
import Spinner from "../../../../temp_iot/src/utils/Spinner";

import axios from "axios";

//GoogleMap
import GoogleMap from "../../../../temp_iot/src/components/component/GoogleMap";

import io from "socket.io-client";
import { API_BACKEND, API_URL } from "../../../../temp_iot/src/actions/types";
import DocumentMeta from "react-document-meta";
import { SearchOutlined } from '@ant-design/icons';
import { AiFillExclamationCircle } from "react-icons/ai";

import { GOOGLE_API_KEY } from "../../../../temp_iot/src/actions/types";

const { Search } = Input;

//META
const meta = {
  title: "PASADA",
  description: "List of all the registered vehicles to be monitored.",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "monitor,pasadaph,pasada",
    },
  },
};

// axios
// .get('https://api.openweathermap.org/data/2.5/weather?lat=14.625833&lon=121.0617466&appid=bb9bcc54dfc31ea1ea2dba7c7a0d914f')
// .catch(function (error) {
//   console.log(error.toJSON());
// })
// .then((response) => {
//   //setting of data to state array if there is an existing sensory data recorded

//   console.log(response.data);

//   TrackAll.updateWeather("NAP 503", response.data.weather[0]['description']);
// });

const TrackAll = () => {
  const [socket, setSocket] = useState(null);

  const [staticCenter, setStaticCenter] = useState({
    lat: 14.62732,
    lng: 121.06279,
  });

  const [trackVehicles, setTrackVehicles] = useState([]);
  const [weatherVar, setWeatherVar] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: "Plate Number",
      dataIndex: "plate_number",
      key: "plate_number",
      sorter:(a, b) => {
        return String(a.plate_number).localeCompare(String(b.plate_number))
      }

    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      sorter: (a, b) => {
        return String(a.vehicle_type).localeCompare(String(b.vehicle_type))
      },
    },
    {
        title: 'Action',
        dataIndex: 'gps_data',
        render: (text, record) => {
            return [
              <Button onClick={() => setStaticCenter(record.gps)} type="link">Go to Marker</Button>
            ]
        }
      },
  ];

  // useEffect(() => {
  //   // axios
  //   // .get('https://api.openweathermap.org/data/2.5/weather?lat=14.6073943&lon=121.0164345&appid=bb9bcc54dfc31ea1ea2dba7c7a0d914f')
  //   // .catch(function (error) {
  //   //   console.log(error.toJSON());
  //   // })
  //   // .then((response) => {
  //   //   //setting of data to state array if there is an existing sensory data recorded

  //   //   console.log(response);
  //   // });

  //   setWeather((current) =>
  //   "test"
  //   )
  // }, []);

  // useEffect(() => {

  //   axios
  //   .get('https://api.openweathermap.org/data/2.5/weather?lat=14.6073943&lon=121.0164345&appid=bb9bcc54dfc31ea1ea2dba7c7a0d914f')
  //   .catch(function (error) {
  //     console.log(error.toJSON());
  //   })
  //   .then((response) => {
  //     //setting of data to state array if there is an existing sensory data recorded

  //     console.log(response.data);

  //     updateWeather("ETM 321", response.data.weather[0]['description']);
  //   });
  // })

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzM2JhZjFkZWI3ODY5NzkyYjU0ODg3MSIsInJvbGUiOiJzdXBlcnVzZXIiLCJpYXQiOjE2Nzc1MDgxMTl9.aY431bRMDInLCT1rP3WACVkeSOitLrIYuGtcyCVTm_Y'
      },
    };
    try {
      axios
        .get(API_URL + `/api/trucks/getTrucks/`, config)
        .catch(function (error) {
          console.log(error.toJSON());
        })
        .then((response) => {
          if (response !== undefined && response.data !== undefined) {
            // Access the data property
            response.data.map((obj) => {
              // console.log(obj.vehicle_id);

              axios
                  .get(API_URL + `/api/trucks/getAllSensoryData/` + obj.vehicle_id)
                  .catch(function (error) {
                    console.log(error.toJSON());
                  })
                  .then((response) => {
                    //setting of data to state array if there is an existing sensory data recorded
                    console.log("Check response");
                    console.log(response);
                    if (response !== undefined && response.data !== undefined) {
                      console.log(response.data.sensory_data.length)
                        if (response.data.sensory_data.length > 0) {
                        fetch(
                            `https://api.openweathermap.org/data/2.5/weather?lat=${
                                response.data.sensory_data[
                                response.data.sensory_data.length - 1
                                    ].latitude
                            }&lon=${
                                response.data.sensory_data[
                                response.data.sensory_data.length - 1
                                    ].longitude
                            }&appid=e62c3135522f9e7616527661f8d249f2`
                        )
                            .then((res) => res.json())
                            .then((json) => {
                              setWeatherVar((current) => [
                                ...current,
                                json.weather[0].description,
                              ]);
                            });

                        setTrackVehicles((current) => [
                          ...current,
                          {
                            assigned_driver:
                            response.data.sensory_data[
                            response.data.sensory_data.length - 1
                                ].assigned_driver,
                            plate_number:
                            response.data.sensory_data[
                            response.data.sensory_data.length - 1
                                ].plate_number,
                            gps: {
                              lat: response.data.sensory_data[
                              response.data.sensory_data.length - 1
                                  ].latitude,
                              lng: response.data.sensory_data[
                              response.data.sensory_data.length - 1
                                  ].longitude,
                            },
                            latitute:
                            response.data.sensory_data[
                            response.data.sensory_data.length - 1
                                ].latitude,
                            longitude:
                            response.data.sensory_data[
                            response.data.sensory_data.length - 1
                                ].longitude,
                            date: response.data.sensory_data[
                            response.data.sensory_data.length - 1
                                ].date,
                            vehicle_type: response.data.vehicle_type,
                          },
                        ]);
                      }}
                  });
            });}

        });
    } catch (err) {
      if (err) {
        console.log("Input Error");
      }
    }
  }, []);

  useEffect(() => {
    setSocket(
      io.connect(API_URL, {
        transports: ["websocket", "polling"],
      })
    );
  }, []);

  const openNotification = (socket_ID) => {
    const key = `open${Date.now()}`;
    notification.info({
      message: "Established Realtime Connection",
      description: `Socket id: ${socket_ID}`,
      key,
    });
  };

  const updateWeather = (plateNum, weather) => {
    setTrackVehicles((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.plate_number === plateNum) {
          return {
            ...obj,
            // gps: paramGps[0],
            // longitude: paramGps[0].lng,
            // latitute: paramGps[0].lat,
            // date: paramDate,
            weather: weather,
          };
        }
        return obj;
      });

      return newState;
    });
  };

  const updateState = (plateNum, paramGps, paramDate) => {
    setTrackVehicles((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.plate_number === plateNum) {
          return {
            ...obj,
            gps: paramGps[0],
            longitude: paramGps[0].lng,
            latitute: paramGps[0].lat,
            date: paramDate,
          };
        }
        return obj;
      });

      return newState;
    });
  };

  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => {
      openNotification(socket.id);
    });
    socket.on("trackAllVehicle", (data) => {
      updateState(data.data.plate_number, data.gps_data.GPS, data.data.date);
    });
    return () => socket.disconnect();
  }, [socket]);

  return (
    <DocumentMeta {...meta}>
      <Fragment>
        <PageHeader
          className="site-page-header page-custom-header"
          title="Track All Vehicles"
          extra={
            <Input placeholder="Type to search.." 
            allowClear 
            className="search"
            // onSearch={(value) => {
            //   setSearchText(value);
            // }} 
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            suffix={<SearchOutlined />} 
            style={{ width: 250 }} 
            />
          }
          // subTitle="| Realtime monitoring of all vehicles"
        />
      </Fragment>

      <Row gutter={16}>
        <Col span={6}>
          <Table 
            columns={columns} 
            dataSource={trackVehicles.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.plate_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
                val.vehicle_type.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: 100,
              showLessItems: true,
              onChange:(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize)
              }
            }}
          />
        </Col>
        <Col span={18}>
          <p style={{paddingLeft: 3, color: "gray" }}>Legend: &ensp; <img src={BusImg} style={{height: "18px", width: "13px"}}></img> Bus &emsp; <img src={JeepneyImg} style={{height: "18px", width: "13px"}}></img> Modern jeep</p>
          <GoogleMap
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              GOOGLE_API_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `85vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultZoom={10}
            zoom={17}
            center={staticCenter}
            trackAllVehicle
            trackAll={trackVehicles}
            weatherOjb={weatherVar}
          />
        </Col>
      </Row>
    </DocumentMeta>
  );
};

export default TrackAll;
