import { Card, Row, Col } from 'antd';
const InfoCard = props => {
  return (
    <Card className='card' style={{marginBottom: "15px"}}>
      <Row >
        <Col className='data-desc' md={{ span: 24 }} lg={{ span: 24 }} >
          <span>{props.desc}</span>
        </Col>
        <Col
          className='data-info'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 8 }}
        >
          <span>{props.info1} </span>
        </Col>
        <Col
          className='data-val'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 16 }}
        >
          <span>{props.value1}</span>
        </Col>
        <Col
          className='data-info'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 8 }}
        >
          <span>{props.info2}</span>
        </Col>
        <Col
          className='data-val'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 16 }}
        >
          <span>{props.value2}</span>
        </Col>
        <Col
          className='data-info'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 8 }}
        >
          <span>{props.info3}</span>
        </Col>
        <Col
          className='data-val'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 16 }}
        >
          <span>{props.value3}</span>
        </Col>
        <Col
          className='data-info'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 8 }}
        >
          <span>{props.info4}</span>
        </Col>
        <Col
          className='data-val'
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 16 }}
        >
          <span>{props.value4}</span>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
