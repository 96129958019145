import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDrivers, deleteDriver } from '../../actions/driver';
import { Table, Button, PageHeader, Popconfirm, message, Input, Space,  } from 'antd';

import Spinner from '../../utils/Spinner';
import AddDriver from '../component/AddDriver';
import Alert from '../../utils/Alert';
import UpdateDriver from '../component/UpdateDriver';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import DocumentMeta from 'react-document-meta';
import Search from 'antd/lib/transfer/search';

//META
const meta = {
  title: 'PASADA',
  description: 'List of all the registered drivers.',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'driver,driverlist,pasada drivers, pasadaph drivers'
    }
  }
}

const Driver = ({
  getDrivers,
  deleteDriver,
  driver: { drivers, loading },
  auth: { loggedInuser },
}) => {
  useEffect(() => {
    getDrivers();
  }, [getDrivers]);
  
  

  const data = [];
  drivers.results.map(drv =>
    data.push({
      key: drv.id,
      firstName: drv.fname,
      lastName: drv.lname,
      fullName: drv.fname+ " " + drv.lname,
      age: drv.email,
      company: drv.organization.org_title,
    })
  );

  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchedColumn, setSearchedColumn] = useState('');

  
  //styles
  const styleFont = {
    fontFamily: 'Inter, sans-serif',
    marginTop: '20px',
  }

  let searchInput;

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={node => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type='primary'
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size='small'
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleReset(clearFilters)}
  //           size='small'
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type='link'
  //           size='small'
  //           onClick={() => {
  //             confirm({ closeDropdown: false });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: filtered => (
  //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : '',
  //   onFilterDropdownVisibleChange: visible => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  //   render: text =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ''}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.fullName).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.age).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.company).toLowerCase().includes(value.toLowerCase());
      // },
      sorter:(a, b) => {
        return String(a.fullName).localeCompare(String(b.fullName))
      }
      // ...getColumnSearchProps('Full Name'),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter:(a, b) => {
        return String(a.age).localeCompare(String(b.age))
      }
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter:(a, b) => {
        return String(a.company).localeCompare(String(b.company))
      }
    },
  ];

  const columnsForNotAdmin = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.fullName).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.age).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.company).toLowerCase().includes(value.toLowerCase());
      // },
      sorter:(a, b) => {
        return String(a.fullName).localeCompare(String(b.fullName))
      }
      // ...getColumnSearchProps('Full Name'),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter:(a, b) => {
        return String(a.age).localeCompare(String(b.age))
      }
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter:(a, b) => {
        return String(a.company).localeCompare(String(b.company))
      }
    },
  ];

  if(loading){
    return <Spinner />
  }
  return loading && drivers === null ? (
    <Spinner />
  ) : (
    <DocumentMeta {...meta}>
    <Fragment>
      {(loggedInuser && loggedInuser.user.role_id === 1) ||
      (loggedInuser && loggedInuser.user.role_id === 3) ? (
        <Fragment>
          <PageHeader
            className='site-page-header page-custom-header'
            title='Drivers'
            // subTitle='| List of all the registered vehicle drivers'
            
            extra={
              <div>
                <Input placeholder="Type to search.." 
            allowClear 
            // onSearch={(value) => {
            //   setSearchText(value);
            // }} 
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            suffix={<SearchOutlined />} 
            style={{ width: 250, marginRight: 10 }} 
            />
                
              </div>
            }
          ></PageHeader>
          <Alert />
         
            <Table
            columns={columns}
            dataSource={data.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                val.age.toLowerCase().includes(searchTerm.toLowerCase()) ||
                val.company.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })}
            size='middle'
            style={styleFont}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: 100,
              onChange:(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize)
              }
            }}
            
          />
        
          
        </Fragment>
      ) : (
        <Fragment>
          <PageHeader
            className='site-page-header page-custom-header'
            title='Drivers'
            // subTitle='| List of all the registered Vehicle drivers'
            extra={
              <div>
                <Input placeholder="Type to search.." 
            allowClear 
            // onSearch={(value) => {
            //   setSearchText(value);
            // }} 
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            suffix={<SearchOutlined />} 
            style={{ width: 250, marginRight: 10 }} 
            />
              </div>
            }
          ></PageHeader>
         
  <Table
  columns={columnsForNotAdmin}
  dataSource={data.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      val.age.toLowerCase().includes(searchTerm.toLowerCase()) ||
      val.company.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return val;
    }
  })}
  style={styleFont}
  pagination={{
    current: page,
    pageSize: pageSize,
    total: 100,
    onChange:(page, pageSize) => {
      setPage(page);
      setPageSize(pageSize)
    }
  }}
/>
        
        
        </Fragment>
      )}
    </Fragment>
    </DocumentMeta>
  );
};

Driver.propTypes = {
  getDrivers: PropTypes.func.isRequired,
  deleteDriver: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  driver: state.driver,
  auth: state.auth,
});

export default connect(mapStateToProps, { getDrivers, deleteDriver })(Driver);
