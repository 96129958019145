import axios from 'axios';
import {setAlert} from './alert';
import { API_URL } from './types';
import {
    GET_PROFILE,
    PROFILE_ERROR
} from './types';

//Get current users profile
export const getCurrentProfile = () => async dispatch => {
    try{
        const res = await axios.get(API_URL+'/api/profile/getMyProfile');
        dispatch({
            type: GET_PROFILE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: PROFILE_ERROR,
            payload: {msg: err.response.data.msg, status: err.response.statusText, type: err.response.status}
        });
    }
};


//Create or Update profile
export const createProfile = (formData, history, edit = false) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post(API_URL+'/api/profile/createProfile', formData, config);
        dispatch({
            type: GET_PROFILE,
            payload: res.data
        });
        dispatch(setAlert(edit ? 'Profile Updated': 'Profile Created', 'success'));

        if(!edit){
            history.push('/profile');
        }
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')))
        }
        dispatch({
            type: PROFILE_ERROR,
            payload: {msg: errors, status: err.response.statusText, type: err.response.status}
        });
    }
} 