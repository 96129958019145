import{
    GET_REGISTERED_USERS,
    GET_REGISTERED_USERS_ERROR,
    DELETE_SPECIFIC_USER,
    DELETE_SPECIFIC_USER_ERROR,
    UPDATE_SPECIFIC_USER,
    UPDATE_SPECIFIC_USER_ERROR,
    REGISTER_SUCESS,
  REGISTER_FAIL,
} from '../actions/types';


const initialState = {
    users: [],
    loading: true,
    error: {},
  };

export const userReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type){
        case DELETE_SPECIFIC_USER:
        case UPDATE_SPECIFIC_USER:
        case GET_REGISTERED_USERS:
        case REGISTER_SUCESS:
            return {
                ...state,
                users: payload,
                loading: false,
            };
        case UPDATE_SPECIFIC_USER_ERROR:
        case GET_REGISTERED_USERS_ERROR:
        case DELETE_SPECIFIC_USER_ERROR:
        case REGISTER_FAIL:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}

export default userReducer;

