
import { Area } from '@ant-design/charts';

const SensorGraphArea = props => {


  var config = {
    data: props.sensorData,
    xField: 'date',
    yField: `${props.yfield}`,
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
   
    slider: {
      start: 0.1,
      end: 0.5,
    },
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' };
    },
  };
  return (
    <Area
      {...config}
      style={{
        width: '100%',
        height: '300px',
        border: 'none',
        boxShadow: '0 3px 6px 0 #0000001a',
        borderRadius: '6px',
        padding: '20px',
        background: '#fff',
        marginTop: '15px',
      }}
    />
  );
};

export default SensorGraphArea;
