import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getTrucks,
  deleteTruck,
  assignDriverToTruck,
  deleteAssignedDriver,
  getHistoryAssignedDrivers,
} from "../../actions/truck";
import { getDrivers } from "../../actions/driver";
import { Table, Button, Modal, PageHeader, Popconfirm, Tabs, List, Space, Card, Input, Tag } from "antd";
import Spinner from "../../utils/Spinner";
import Alert from "../../utils/Alert";
import TruckActions from "../component/TruckActions";
import UpdateTruck from "../component/UpdateTruck";
import Highlighter from "react-highlight-words";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import DocumentMeta from "react-document-meta";
import { Link } from 'react-router-dom';
import moment from 'moment';

const { Search } = Input;
const { TabPane } = Tabs;
//META
const meta = {
  title: "PASADA",
  description: "List of all the registered vehicles.",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "bus,jeep,bus list,jeep list,pasada, pasadaph, pasada bus and jeep",
    },
  },
};

const Truck = ({
  props,
  getTrucks,
  getDrivers,
  assignDriverToTruck,
  deleteAssignedDriver,
  getHistoryAssignedDrivers,
  deleteTruck,
  truck: { trucks, loading, history_assigned_driver },
  driver: { drivers },
  auth: { loggedInuser },
}) => {
  useEffect(() => {
    getTrucks();
    getDrivers();
    getHistoryAssignedDrivers();
  }, [getTrucks, getDrivers, getHistoryAssignedDrivers]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalAssignVisible, setIsModalAssignVisible] = useState(false);
  const [getVehicleId, setVehicleId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const [datas, setData] = useState(props);

  const handleAssignOk = () => {
    setIsModalAssignVisible(false);
  };

  const handleAssignCancel = () => {
    setIsModalAssignVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSearchForAssignedDrivers = (e) => {
    setSearchValue(e.target.value);
    setPageNumber(1);
  };
  const historyAssignedDriversData = [];
  const data = [];


  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchedColumn, setSearchedColumn] = useState("");

  let searchInput;

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={(node) => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: "block" }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({ closeDropdown: false });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : "",
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    // {
    //   title: "Vehicle id",
    //   dataIndex: "vehicle_id",
    //   key: "vehicle_id",
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.vehicle_type).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.plate_number).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.full_name).toLowerCase().includes(value.toLowerCase());
      // }
      // ...getColumnSearchProps("vehicle_id"),
    // },},
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      filters: [
        {
          text: 'Bus',
          value: 'Bus',
        },
        {
          text: 'Jeepney',
          value: 'Jeepney',
        },
      ],
      onFilter: (value, record) => record.vehicle_type.indexOf(value) === 0,
      render: vehicle_type => {
        return [
          vehicle_type === 'Bus' ? (
            <Tag color='blue'>{vehicle_type.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          vehicle_type === 'Jeepney' ? (
            <Tag color='red'>{vehicle_type.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
        ];
      },
    },
      // ...getColumnSearchProps("vehicle_type"),
    {
      title: "Plate number",
      dataIndex: "plate_number",
      key: "plate_number",
      sorter:(a, b) => {
        return String(a.plate_number).localeCompare(String(b.plate_number))
      }
      // ...getColumnSearchProps("plate_number"),
    },
    {
      title: "Assigned Driver",
      dataIndex: "full_name",
      key: "full_name",
      sorter:(a, b) => {
        return String(a.full_name).localeCompare(String(b.full_name))
      }
      // ...getColumnSearchProps("full_name"),
    },
    {
      title: "Date Assigned",
      dataIndex: "date_assigned",
      key: "date_assigned",
      sorter:(a, b) => {
        return String(a.date_assigned).localeCompare(String(b.date_assigned))
      }
      // ...getColumnSearchProps("date_assigned"),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => {
        return [
          <>
            {record.full_name.length !== 0 ? (
              <>
              <Link to={{
                  pathname: `/monitor/${record.vehicle_id}`,
                  state: datas,
                }}
                >
                <Button
                  style={{ margin: '10px 0 0 10px', fontFamily: 'Inter, sans-serif' }}
                  type='primary'
                  size='middle'
                >
                Monitor
                </Button>
               </Link>
                <UpdateTruck 
                  vehicleid={record.vehicle_id}
                  plateNumber={record.plate_number}
                  />
                <Popconfirm
                  title="Are you sure you want to do this?"
                  onConfirm={() => {
                    const vehicle_id = record.vehicle_id;
                    const assigned_driver_id = record._id;
                    deleteAssignedDriver(vehicle_id, assigned_driver_id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      marginLeft: "10px",
                      background: "#e9d41e",
                      color: "white",
                    }}
                    type="default"
                  >
                    Unassign Driver
                  </Button>
                </Popconfirm>
                <Button type="danger" disabled style={{ marginLeft: "10px",}}>
                  Delete
                </Button>
                {/* <UpdateTruck vehicleid={record.vehicle_id} /> */}
                {/* <Link to={{
                  pathname: `/monitor/${record.vehicle_id}`,
                  state: datas,
                }}
                >
                <Button
                  style={{ margin: '10px 0 0 10px', fontFamily: 'Inter, sans-serif' }}
                  type='primary'
                  size='middle'
                >
                Monitor
                </Button>
               </Link> */}
              </>
            ) : (
              <>
                <Link to={{
                  pathname: `/monitor/${record.vehicle_id}`,
                  state: datas,
                }}
                >
                <Button
                  style={{ margin: '10px 0 0 10px', fontFamily: 'Inter, sans-serif' }}
                  type='primary'
                  size='middle'
                >
                Monitor
                </Button>
               </Link>
               <UpdateTruck 
                  vehicleid={record.vehicle_id}
                  plateNumber={record.plate_number}
                  />
                <Modal
                  title="Assign Driver"
                  visible={isModalAssignVisible}
                  onOk={handleAssignOk}
                  onCancel={handleAssignCancel}
                >
                  <p style={{ fontFamily: "Inter, sans-serif", marginLeft: "12px" }}>
                    Here is the names listed in the registered drivers that you
                    can assign to a specific vehicle.
                  </p>
                  <List
                    size="small"
                    itemLayout="vertical"
                    dataSource={drivers && drivers}
                    renderItem={(driver) => (
                      <List.Item
                        extra={
                          <Button
                            type="primary"
                            onClick={() => {
                              const last_name = driver.last_name;
                              const first_name = driver.first_name;
                              const company = driver.company;
                              assignDriverToTruck(
                                getVehicleId,
                                first_name,
                                last_name,
                                company
                              );

                              setIsModalAssignVisible(false);
                            }}
                            style={{marginTop: "35px"}}
                          >
                            Assign
                          </Button>
                        }
                      >
                        <h1
                          style={{
                            fontFamily: "Inter, sans-serif",
                            fontSize: "15px",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {`${driver.first_name} ${driver.last_name}`}
                        </h1>
                      </List.Item>
                    )}
                  ></List>
                </Modal>
                <Button
                  style={{ marginLeft: "10px", paddingLeft: "22px", paddingRight: "23px"}}
                  onClick={() => {
                    setVehicleId(record.vehicle_id);
                    setIsModalAssignVisible(true);
                  }}
                  type="dashed"
                >
                  Assign Driver
                </Button>
                <Popconfirm
                  title="Are you sure to delete this vehicle?"
                  onConfirm={() => {
                    deleteTruck(record.vehicle_id);
                    window.location.reload(false);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" style={{marginLeft: "10px"}}>Delete</Button>
                </Popconfirm>{" "}
              </>
            )}
          </>,
        ];
      },
    },
  ];

  const columnsForNotAdmin = [
    // {
      // title: "Vehicle id",
      // dataIndex: "vehicle_id",
      // key: "vehicle_id",
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.vehicle_id).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.vehicle_type).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.plate_number).toLowerCase().includes(value.toLowerCase());
      // }
      // ...getColumnSearchProps("vehicle_id"),
    // },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      filters: [
        {
          text: 'Bus',
          value: 'Bus',
        },
        {
          text: 'Jeepney',
          value: 'Jeepney',
        },
      ],
      onFilter: (value, record) => record.vehicle_type.indexOf(value) === 0,
      render: vehicle_type => {
        return [
          vehicle_type === 'Bus' ? (
            <Tag color='blue'>{vehicle_type.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          vehicle_type === 'Jeepney' ? (
            <Tag color='red'>{vehicle_type.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
        ];
      },
      // ...getColumnSearchProps("vehicle_type"),
    },
    {
      title: "Plate number",
      dataIndex: "plate_number",
      key: "plate_number",
      sorter:(a, b) => {
        return String(a.plate_number).localeCompare(String(b.plate_number))
      }
      // ...getColumnSearchProps("plate_number"),
    },
  ];

  // history_assigned_driver
  //   .filter((val) => {
  //     if (searchValue == "") {
  //       return val.plate_number;
  //     } else if (
  //       val.plate_number
  //         .toLowerCase()
  //         .includes(searchValue.toLowerCase())
  //     ) {
  //       return val.plate_number;
  //     }
  //   })
  //   .map((val) => {
  //     const convertStartDateFormat = new Date(val.startDate);
  //     const finalStartDate = `${
  //       convertStartDateFormat.getMonth() + 1
  //     }/${convertStartDateFormat.getFullYear()}/${convertStartDateFormat.getDate()} - ${convertStartDateFormat.toLocaleString(
  //       "en-US",
  //       {
  //         hour: "numeric",
  //         minute: "numeric",
  //         second: "numeric",
  //         hour12: true,
  //       }
  //     )}`;
  //     const convertEndDateFormat = new Date(val.endDate);
  //     const finalEndDate = `${
  //       convertEndDateFormat.getMonth() + 1
  //     }/${convertEndDateFormat.getFullYear()}/${convertEndDateFormat.getDate()} - ${convertEndDateFormat.toLocaleString(
  //       "en-US",
  //       {
  //         hour: "numeric",
  //         minute: "numeric",
  //         second: "numeric",
  //         hour12: true,
  //       }
  //     )}`;

  //     // key = key + 1;
  //     // return (
  //     //   <>
  //     //   </>
  //     // );
  //   });


  const historyColumns = [
    {
      title: "Assigned Driver",
      dataIndex: "fullName",
      key: "fullName",
      sorter:(a, b) => {
        return String(a.fullName).localeCompare(String(b.fullName))
      }
    },
    {
      title: "Plate number",
      dataIndex: "plate_number",
      key: "plate_number",
      sorter:(a, b) => {
        return String(a.plate_number).localeCompare(String(b.plate_number))
      }
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => { 
        return (<p>{moment(start_date).format("MM/DD/YYYY - hh:mm:ss A")}</p>)
      },
      sorter:(a, b) => {
        return String(a.start_date).localeCompare(String(b.start_date))
      }
      
    },
    
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (end_date) => {
        return (<p>{moment(end_date).format("MM/DD/YYYY - hh:mm:ss A")}</p>)
      },
      sorter:(a, b) => {
        return String(a.end_date).localeCompare(String(b.end_date))
      }
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter:(a, b) => {
        return String(a.company).localeCompare(String(b.company))
      }
    },
  ];

  if (!history_assigned_driver) {
    return <Spinner />;
  }
  if (loading) {
    return <Spinner />;
  }
  return loading && trucks === null ? (
    <Spinner />
  ) : (
    <DocumentMeta {...meta}>
      <Tabs type="card">
        <TabPane tab="List of Vehicles" key="1">
          <Fragment>
            {(loggedInuser && loggedInuser.user.role_id === 1) ||
            (loggedInuser && loggedInuser.user.role_id === 3) ? (
              <Fragment>
                <PageHeader
                  className="site-page-header page-custom-header"
                  title="Vehicles"
                  // subTitle="| List of all the registered vehicles"
                  extra={
                    <div>
                      <Input placeholder="Type to search.." allowClear 
                      // onSearch={(value) => {
                      //   setSearchText(value);
                     // }} 
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      suffix={<SearchOutlined />} 
                      style={{ width: 250, marginRight: 10 }} 
                      />
                      <Button
                        type="primary"
                        style={{
                          float: "right",
                        }}
                        onClick={showModal}
                        icon={<PlusOutlined />}
                      >
                        Add Vehicle
                      </Button>
                      <Modal
                        title="Add Truck"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleCancel}>
                            Back
                          </Button>,
                        ]}
                      >
                        <TruckActions />
                      </Modal>
                    </div>
                  }
                ></PageHeader>
                <Alert />
                <Table
                  columns={columns}

                  size="middle"
                  style={{
                    fontFamily: "Inter, sans-serif",
                    marginTop: "20px",
                  }}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: 100,
                    onChange:(page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize)
                    }
                  }}
                />
              </Fragment>
            ) : (
              <Fragment>
                <PageHeader
                  className="site-page-header page-custom-header"
                  title="Vehicles"
                  // subTitle="| List of all the registered vehicles"
                  extra={
                    <Input placeholder="Type to search.." 
                    allowClear 
                    // onSearch={(value) => {
                    //   setSearchText(value);
                    // }} 
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    suffix={<SearchOutlined />} 
                    style={{ width: 250 }} 
                    />
                  }
                ></PageHeader>
                <Table
                  columns={columnsForNotAdmin}
                  style={{
                    fontFamily: "Inter, sans-serif",
                    marginTop: "20px"
                  }}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: 100,
                    onChange:(page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize)
                    }
                  }}
                />
              </Fragment>
            )}
          </Fragment>
        </TabPane>

        
        <TabPane tab="History of Assigned Drivers" key="2">
          {/* <p>
            <span style={{ color: "#2db7f5", fontWeight: "bold" }}>Note: </span>
            Enter the plate number to see all the assigned drivers to this
            vehicle.
          </p>
          <Search
            placeholder="Enter Plate Number"
            allowClear
            enterButton
            onChange={handleSearchForAssignedDrivers}
            style={{ width: 304 }}
          /> */}
          <Fragment>
                <PageHeader
                  className="site-page-header page-custom-header"
                  title="History of Assigned Drivers"
                  // subTitle="| List of all the registered vehicles"
                  extra={
                    <Input placeholder="Type to search.." 
                    allowClear 
                    // onSearch={(value) => {
                    //   setSearchText(value);
                    // }} 
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    suffix={<SearchOutlined />} 
                    style={{ width: 250 }} 
                    />
                  }
                ></PageHeader>
                <Table
                  columns={historyColumns}
                  dataSource={historyAssignedDriversData.filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      val.start_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      val.end_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      val.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      val.plate_number.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                  })}
                  style={{
                    fontFamily: "Inter, sans-serif",
                    marginTop: "20px"
                  }}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: 100,
                    onChange:(page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize)
                    }
                  }}
                />
              </Fragment>

          {/* <Card style={{ marginTop: "15px" }}>
            <List itemLayout="horizontal">
              {history_assigned_driver
                .filter((val) => {
                  if (searchValue == "") {
                    return val.plate_number;
                  } else if (
                    val.plate_number
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  ) {
                    return val.plate_number;
                  }
                })
                .map((val, key) => {
                  const convertStartDateFormat = new Date(val.startDate);
                  const finalStartDate = `${
                    convertStartDateFormat.getMonth() + 1
                  }/${convertStartDateFormat.getFullYear()}/${convertStartDateFormat.getDate()} - ${convertStartDateFormat.toLocaleString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    }
                  )}`;
                  const convertEndDateFormat = new Date(val.endDate);
                  const finalEndDate = `${
                    convertEndDateFormat.getMonth() + 1
                  }/${convertEndDateFormat.getFullYear()}/${convertEndDateFormat.getDate()} - ${convertEndDateFormat.toLocaleString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    }
                  )}`;

                  key = key + 1;
                  return (
                    <>
                    
                      <List.Item key={key}>
                        <div
                          style={{
                            padding: "2em 3em 2em 2em",
                            borderRadius: "100%",
                          }}
                        >
                          <Tag color="default">{key}</Tag>
                        </div>
                        <List.Item.Meta
                          title={`${val.first_name} ${val.last_name}`}
                          description="Assigned Driver"
                        />
                        <List.Item.Meta
                          title={val.plate_number}
                          description="Plate Number"
                        />
                        <List.Item.Meta
                          title={finalStartDate}
                          description="Start Date"
                        />
                        <List.Item.Meta
                          title={finalEndDate}
                          description="End Date"
                        />
                        <List.Item.Meta
                          title={val.company}
                          description="Company"
                        />
                      </List.Item>
                    </>
                  );
                })}
            </List>
          </Card> */}
        </TabPane>
      </Tabs>
    </DocumentMeta>
  );
};

Truck.propTypes = {
  getTrucks: PropTypes.func.isRequired,
  getDrivers: PropTypes.func.isRequired,
  assignDriverToTruck: PropTypes.func.isRequired,
  getHistoryAssignedDrivers: PropTypes.func.isRequired,
  deleteAssignedDriver: PropTypes.func.isRequired,
  deleteTruck: PropTypes.func.isRequired,
  truck: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  truck: state.truck,
  driver: state.driver,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getTrucks,
  deleteTruck,
  getDrivers,
  assignDriverToTruck,
  deleteAssignedDriver,
  getHistoryAssignedDrivers,
})(Truck);
