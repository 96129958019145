import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import XLSX from 'xlsx';
import Alert from '../../utils/Alert';
import { getRegisteredUsers } from '../../actions/user';
import { deleteSpecificUser } from '../../actions/user';
import { Button, PageHeader, Table, Modal, Drawer, Tag, Popconfirm, Input, Space } from 'antd';
import Spinner from '../../utils/Spinner';
import RegisterUserActions from '../component/RegisterUserActions';
import UpdateUser from '../component/UpdateUser';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import DocumentMeta from 'react-document-meta';

//META
const meta = {
  title: 'PASADA',
  description: 'List of all the registered users.',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'users,userlist,pasada users,pasadaph users'
    }
  }
}

const RegisterUser = ({
  getRegisteredUsers,
  deleteSpecificUser,
  user: {users, loading},
  auth: { loggedInuser },
}) => {
  useEffect(() => {
    getRegisteredUsers();
    
  }, [getRegisteredUsers]);
 
  const [dataSource, getDataSource] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [searchedColumn, setSearchedColumn] = useState('');



  let searchInput;

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={node => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type='primary'
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size='small'
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleReset(clearFilters)}
  //           size='small'
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type='link'
  //           size='small'
  //           onClick={() => {
  //             confirm({ closeDropdown: false });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: filtered => (
  //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : '',
  //   onFilterDropdownVisibleChange: visible => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  //   render: text =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ''}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // const handleReset = clearFilters => {
  //   clearFilters();
  //   setSearchText('');
  // };
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

   const handleCancel = () => {
    setIsModalVisible(false)
  };

  const columnsForNotSuperUser = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.fullName).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.role).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.email).toLowerCase().includes(value.toLowerCase());
      // },
      sorter:(a, b) => {
        return String(a.fullName).localeCompare(String(b.fullName))
      }
      // ...getColumnSearchProps('Full Name'),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: '_id',
      filters: [
        {
          text: 'Super User',
          value: 'superuser',
        },
        {
          text: 'Operator',
          value: 'operator',
        },
        {
          text: 'Driver',
          value: 'driver',
        },
      ],

      onFilter: (value, record) => record.role.indexOf(value) === 0,
      render: role => {
        return [
          role === 'superuser' ? (
            <Tag color='purple'>{role.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          role === 'operator' ? (
            <Tag color='cyan'>{role.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          role === 'driver' ? (
            <Tag color='default'>{role.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
        ];
      },
    },

    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      sorter:(a, b) => {
        return String(a.email).localeCompare(String(b.email))
      }
      // ...getColumnSearchProps('email'),
    },
  ];
  const columnsForSuperUser = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.fullName).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.role).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.email).toLowerCase().includes(value.toLowerCase());
      // },
      sorter:(a, b) => {
        return String(a.fullName).localeCompare(String(b.fullName))
      }
      // ...getColumnSearchProps('Full Name'),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: '_id',
      filters: [
        {
          text: 'Super User',
          value: 'superuser',
        },
        {
          text: 'Operator',
          value: 'operator',
        },
        {
          text: 'Driver',
          value: 'driver',
        },
      ],
      sorter:(a, b) => {
        return String(a.role).localeCompare(String(b.role))
      },
      onFilter: (value, record) => record.role.indexOf(value) === 0,
      render: role => {
        return [
          role === 'superuser' ? (
            <Tag color='purple'>{role.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          role === 'operator' ? (
            <Tag color='cyan'>{role.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          role === 'driver' ? (
            <Tag color='default'>{role.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
        ];
      },
    },

    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      sorter:(a, b) => {
        return String(a.email).localeCompare(String(b.email))
      }
      // ...getColumnSearchProps('email'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: '_id',
      render: (text, record) => {
        return [
          <DocumentMeta {...meta}>
            <Fragment>
              <UpdateUser
                userFirstName={record.firstName}
                userLastName={record.lastName}
                userEmail={record.email}
              />
              <Popconfirm
                title='Are you sure you want to delete this user?'
                onConfirm={() => {
                  const email_address = record.email;
                  deleteSpecificUser(email_address);
                
                }}
                okText='Yes'
                cancelText='No'
              >
                <Button type='danger'>Delete</Button>
              </Popconfirm>
            </Fragment>
            </DocumentMeta>
        ];
      },
    },
  ];

  // const [showDrawer, setShowDrawer] = useState(false);
  // const handleOnClose = () => {
  //   setShowDrawer(false);
  // };

  const downloadCSV = () => {
    let worksheet;
    if (dataSource.length > 0) {
      worksheet = XLSX.utils.json_to_sheet(dataSource);
    } else {
      worksheet = XLSX.utils.json_to_sheet(data);
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(workbook, 'Users.xlsx');
  };

  const onChange = (pagination, filters, sorter, extra) => {
    getDataSource(extra.currentDataSource);

  };

  const data = [];

  users && users.map(user =>
    data.push({
      key: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.firstName + " " + user.lastName,
      role: user.role,
      email: user.email,
    })
  );
if(loading){
  return <Spinner />
}
  return loading && users === null ? (
    <>
      <Spinner />
    </>
  ) : (
    <>
      <Fragment>
        <PageHeader
          className='site-page-header page-custom-header'
          title='Users'
          // subTitle='| List of all the registered users'
         
          extra={
            <Fragment>
              <Button type='link' onClick={downloadCSV}>
                Download as XLSX
              </Button>
              <Input placeholder="Type to search.." 
            allowClear 
            // onSearch={(value) => {
            //   setSearchText(value);
            // }} 
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            suffix={<SearchOutlined />} 
            style={{ width: 250, marginRight: 5 }} 
            />
              {loggedInuser && loggedInuser.user.role_id === 1 ? (  <Button
                type='primary'
                style={{ float: 'right' }}
                onClick={showModal}
                icon={<PlusOutlined />}
              >
                Add User
              </Button>):(<></>)}
            

              <Modal
                title='Create a new user'
                width={550}
                visible={isModalVisible}
                onCancel={handleCancel}
                bodyStyle={{ paddingBottom: 30 }}
                footer={[
                  <Button key='back' onClick={handleCancel}>
                    Back
                  </Button>,
                ]}
              >
                <RegisterUserActions />
              </Modal>
            </Fragment>
          }
        />
        <Alert />
        {loggedInuser && loggedInuser.user.role_id === 1 ? (<>
          <Table
        
        columns={columnsForSuperUser}
        dataSource={data.filter((val) => {
          if (searchTerm === "") {
            return val;
          } else if (
            val.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            val.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
            val.email.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return val;
          }
        })}
        onChange={onChange}
        size='middle'
        style={{
          fontFamily: 'Inter, sans-serif',

          marginTop: '20px',
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: 100,
          onChange:(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize)
          }
        }}
        
      /></>): (<>
       <Table
        columns={columnsForNotSuperUser}
        dataSource={data.filter((val) => {
          if (searchTerm === "") {
            return val;
          } else if (
            val.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            val.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
            val.email.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return val;
          }
        })}
        onChange={onChange}
        size='middle'
        style={{
          fontFamily: 'Inter, sans-serif',
          marginTop: '20px',
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: 500,
          onChange:(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize)
          }
        }}
        
        
      />
      </>)}
       
      </Fragment>
    </>
  );
};
RegisterUser.propTypes = {
  getRegisteredUsers: PropTypes.func.isRequired,
  deleteSpecificUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,

});

export default connect(mapStateToProps, {
  getRegisteredUsers,
  deleteSpecificUser,
})(RegisterUser);
