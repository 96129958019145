import React, { useState, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile } from '../../actions/profile';
import Alert from '../../utils/Alert';
import { PageHeader, Card, Form, Input, Divider, Button } from 'antd';

const CreateProfile = ({ createProfile, history }) => {
  const [formData, setFormData] = useState({
    company: '',
    location: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
  });

  const { company, location, facebook, twitter, linkedin, instagram } =
    formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    createProfile(formData, history);
  };
  return (
    <Fragment>
      <PageHeader
        className='site-page-header page-custom-header'
        title='Setup Account'
        subTitle='| Make your profile stand out.'
      
      ></PageHeader>
      <Card style={{ width: '600px'}}>
        <Alert />
        <Form
          layout='vertical'
          style={{fontFamily: 'Inter, sans-serif'}}
          labelCol={{ span: 4 }}
          onFinish={e => onSubmit(e)}
        >
          <Divider style={{fontSize: "17px",  color: '#495172', fontWeight: "bold"}}>Personal Information</Divider>
          <Form.Item label='Company' style={{ fontFamily: 'Inter, sans-serif' }}>
            <Input
              style={{ width: '550px' }}
              placeholder='Enter your current company'
              name='company'
              value={company}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Location'>
            <Input
              style={{ width: '550px' }}
              placeholder='Enter your current location'
              name='location'
              value={location}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Divider style={{fontSize: "17px",  color: '#495172', fontWeight: "bold"}}>Optional</Divider>
          <Form.Item label='Facebook'>
            <Input
              style={{ width: '550px' }}
              placeholder='Facebook url'
              name='facebook'
              value={facebook}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Twitter'>
            <Input
              style={{ width: '550px' }}
              placeholder='Twitter url'
              name='twitter'
              value={twitter}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Linkedin'>
            <Input
              style={{ width: '550px' }}
              placeholder='Linkedin url'
              name='linkedin'
              value={linkedin}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Instagram'>
            <Input
              style={{ width: '550px' }}
              placeholder='Instagram url'
              name='instagram'
              value={instagram}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Button
            type='primary'
            style={{ marginBottom: '10px', height: '35px' }}
            htmlType='submit'
            block
          >
            Submit
          </Button>
          <Link to='/profile'>
            <Button
              type='default'
              style={{ height: '35px', width: '550px' }}
              block
            >
              Cancel
            </Button>
          </Link>
        </Form>
      </Card>
    </Fragment>
  );
};

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
};

export default connect(null, { createProfile })(withRouter(CreateProfile));
