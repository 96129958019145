
import { Card, Statistic, Row, Col, Tooltip, Divider, Popover } from 'antd';


const SensorCard = props => {
 
  
  return (

    <Popover content={props.content} title={props.legendTitle}>
    
      <Card
       className='card'
       style={props.customStyle}
        cover={
          <main
            style={{
              height: '45px',
              width: '100%',
            }}
          >
           
            <h1
              className="sensor-card-desc"
              style={{ color: '#000',}}
            >
             
              {props.title}
              <Divider style={{marginTop: "5px"}}/>
            </h1>
          </main>
        }
      >
        <Row style={{ marginBottom: '0' }}>
          <Col span={15}>
            <Statistic
              title={props.sectitle}
              value={props.value}
              valueStyle={{
                // color: '#464C6B',
                color: '#000',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}
              precision={2}
              suffix={props.suffix}
              className="sensor-card-stats"
            />
          </Col>
          <Col span={9}>{props.icon}</Col>
        </Row>
      </Card>
    
    </Popover>
  );
};

export default SensorCard;
