import { Fragment, useState } from 'react';
import { Form, Input, Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '../../utils/Alert';
import { updateDriver } from '../../actions/driver';
import Spinner from '../../utils/Spinner';

const UpdateDriver = ({
  updateDriver,
  driverFirstName,
  driverLastName,
  driverAge,
  driverCompany,
  id,
}) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    age: '',
    company: '',
  });

  const { first_name, last_name, age, company } = formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    updateDriver(formData, id);
    console.log(formData);
    handleOk();
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Fragment>
      <Button
        type='primary'
        style={{
          marginRight: '10px',
        }}
        onClick={showModal}
      >
        Edit
      </Button>
      <Modal
        title='Edit Driver'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Back
          </Button>,
        ]}
      >
        <Alert />

        <Form
          layout='vertical'
          onFinish={e => onSubmit(e)}
          style={{ fontFamily: 'Inter, sans-serif' }}
        >
          <Form.Item label='First Name' name="firstname"  
          rules={[
          {
            required: true,
            message: 'Please input your firstname.',
          },
        ]}>
            <Input
              style={{ width: '465px' }}
              placeholder={driverFirstName}
              name='first_name'
              value={first_name}
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Last Name' name="lastname"  
          rules={[
          {
            required: true,
            message: 'Please input your lastname.',
          },
        ]}>
            <Input
              style={{ width: '465px' }}
              placeholder={driverLastName}
              value={last_name}
              name='last_name'
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Age'  name="age"  
          rules={[
          {
            required: true,
            message: 'Please input your age.',
          },
        ]}>
            <Input
              style={{ width: '465px' }}
              placeholder={driverAge}
              type='number'
              value={age}
              name='age'
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Form.Item label='Company' name="company"  
          rules={[
          {
            required: true,
            message: 'Please input your company.',
          },
        ]}>
            <Input
              style={{ width: '465px' }}
              placeholder={driverCompany}
              value={company}
              name='company'
              onChange={e => onChange(e)}
            />
          </Form.Item>
          <Button
            type='primary'
            style={{
              marginBottom: '10px',
              height: '35px',
              fontFamily: 'Inter, sans-serif',
            }}
            htmlType='submit'
            block
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </Fragment>
  );
};
UpdateDriver.propTypes = {
  updateDriver: PropTypes.func.isRequired,
};
export default connect(null, { updateDriver })(UpdateDriver);
