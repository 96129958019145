import { useEffect, Fragment, useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination , Row, PageHeader, Empty, Input, Table, Space, Button, Layout, Tag} from 'antd';
import Card from '../../../../temp_iot/src/components/component/MonitorCard';
import { getTrucks } from '../../../../temp_iot/src/actions/truck';
import { loadUser } from '../../../../temp_iot/src/actions/auth';
import Spinner from '../../../../temp_iot/src/utils/Spinner';
import DocumentMeta from 'react-document-meta';
import { SearchOutlined, SecurityScanFilled } from '@ant-design/icons';
import { AiFillExclamationCircle } from 'react-icons/ai';
import Highlighter from 'react-highlight-words';
import { Link, useParams } from 'react-router-dom';


//META	
const meta = {
  title: 'PASADA',
  description: 'List of all the registered vehicles to be monitored.',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'monitor, pasadaph, pasada, pasada monitoring'
    }
  }
}

const monitorTruck = data => {
  return (
    <Card
      key={data._id}
      vehicle_id={data.vehicle_id}
      vehicle_type={data.vehicle_type}
      plate_number={data.plate_number}
    />
  );
};

const Monitor = ({props, loadUser, getTrucks, truck: { trucks, loading } }) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    loadUser();
    getTrucks();
  }, [loadUser, getTrucks])

  let params = useParams();
  const [vehicleId] = useState(params.vehicle_id);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [datas, setData] = useState(props);
  const showTotal = (total) => `Total ${total} items`;

  const data = [];
    trucks.map((truck) =>
    data.push({
      key: truck._id,
      vehicle_id: truck.vehicle_id,
      vehicle_type: truck.vehicle_type,
      plate_number: truck.plate_number,
    })
  );
  

  // let searchInput;

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={node => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type='primary'
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size='small'
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => 
  //             handleReset(clearFilters)}
  //           size='small'
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type='link'
  //           size='small'
  //           onClick={() => {
  //             confirm({ closeDropdown: true });
  //             clearFilters();
  //             setSearchText('');
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: filtered => (
  //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : '',
  //   onFilterDropdownVisibleChange: visible => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  //   render: text =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ''}
  //       />
  //     ) : (
  //       text
  //     ),
  // });
    
  const column = [
    {
      title: "Vehicle id",
      dataIndex: "vehicle_id",
      key: "vehicle_id",
      // filteredValue: [searchText],
      // onFilter: (value, record) => {
      //   return String(record.vehicle_type).toLowerCase().includes(value.toLowerCase()) ||
      //          String(record.plate_number).toLowerCase().includes(value.toLowerCase());
      // }
      // ...getColumnSearchProps('vehicle_id'),
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      // sorter:(a, b) => {
      //   return String(a.vehicle_type).localeCompare(String(b.vehicle_type))
      // },
      filters: [
        {
          text: 'Bus',
          value: 'Bus',
        },
        {
          text: 'Jeepney',
          value: 'Jeepney',
        },
      ],
      onFilter: (value, record) => record.vehicle_type.indexOf(value) === 0,
      render: vehicle_type => {
        return [
          vehicle_type === 'Bus' ? (
            <Tag color='blue'>{vehicle_type.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
          vehicle_type === 'Jeepney' ? (
            <Tag color='red'>{vehicle_type.toUpperCase()}</Tag>
          ) : (
            <></>
          ),
        ];
      },
    },


    //   render: vehicle_type  => {
    //     return [
    //       vehicle_type === 'Bus' ? (
    //         <Tag color='blue'>{vehicle_type.toUpperCase()}</Tag>
    //       ) : (
    //         <></>
    //       ),
    //       vehicle_type === 'Jeepney' ? (
    //         <Tag color='red'>{vehicle_type.toUpperCase()}</Tag>
    //       ) : (
    //         <></>
    //       ),
    //     ];
    //   },
    // },
    {
      title: "Plate Number",
      dataIndex: "plate_number",
      key: "plate_number",
      sorter:(a, b) => {
        return String(a.plate_number).localeCompare(String(b.plate_number))
      }
      // ...getColumnSearchProps('plate_number'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key:'actions',
      render: (text, record) => {
        return [
          <Link
          to={{
            pathname: `/monitor/${record.vehicle_id}`,
            state: datas,
          }}
        >
          <Button
            style={{ margin: '10px 0 0 0', fontFamily: 'Inter, sans-serif' }}
            type='primary'
            size='middle'
            block={true}
          >
            Monitor
          </Button>
        </Link>
        ]
      }
    }
  ]

  // const handleReset = clearFilters => {
  //   clearFilters();
  //   setSearchText('');
  // };

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  if(loading){
    return <Spinner />
  }

  return loading && trucks === null ? (
    <Spinner />
  ) : (
    <DocumentMeta {...meta}>
    <Fragment>
     
        <PageHeader
          className='site-page-header page-custom-header'
          title='Monitoring'
          extra={
            <Input placeholder="Type to search.." 
            allowClear 
            className="search"
            // onSearch={(value) => {
            //   setSearchText(value);
            // }} 
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            suffix={<SearchOutlined />} 
            style={{ width: 250 }} 
            />
          }
        />
            <Table
            columns={column}
            dataSource={data.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.vehicle_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                val.plate_number.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })}
            size='middle'
            pagination={{
              current: page,
              pageSize: pageSize,
              total: 100,
              onChange:(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize)
              }
            }}
          />
            {/* <Pagination size="small" total={50} showSizeChanger showQuickJumper /> */}

    </Fragment>
    </DocumentMeta>
  );
};
Monitor.propTypes = {
  getTrucks: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  truck: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  truck: state.truck,
});
export default connect(mapStateToProps, { getTrucks, loadUser })(Monitor);
